// Base
import React from "react";

// Global Layout
import Layout from "../components/layout/layout";

// Components
import Hero from "../components/hero/hero";
import FullWidthContainer from "../components/full-width-container/full-width-container";
import { StaticImage } from "gatsby-plugin-image";
import SectionHeader from "../components/section-header/section-header";
import NestedContainer from "../components/nested-container/nested-container";
import Row6633 from "../components/row/row-66-33";
import RowEqual from "../components/row/row-equal";
import TestimonialCallout from "../components/testimonial-callout/testimonial-callout";
import IconGridArrow from "../components/IconGridArrow/IconGridArrow";
import StatContainer from "../components/stat/stat-container";
import Stat from "../components/stat/stat";
import TaraImage from "../images/customers/tara-demoura-headshot.png"

//Stylesheets
import "./customers.css";
import "./styles.pillar-pages.css";

const IndexPage = () => {
  return (
    <Layout pageTitle="2022 BCBSRI Mission Report - Customers">
      <div className="customers-page">
        <FullWidthContainer
          singleHero
          background={`var(--beige-to-white)`}
        >
          <Hero
            title="Customers"
            intro="Our membership continued to grow in 2022, with more employers and individuals choosing our top-rated plans and top-rated customer experience. Our focus on customers helped us recognize the struggles many people still face, so we are expanding help for members at home and in the community."
            reportType="customers"
            inner
            community
            partners
          >
            <StaticImage
              src="../images/customers/Customers-Hero.jpg"
              alt=""
              loading={"eager"}
              aspectRatio={5 / 2}
              className={"customers-hero"}
            />
          </Hero>
        </FullWidthContainer>
        <div id="earning-top-marks-from-members">
          <FullWidthContainer
            noBottomPad
            bottomBlockBlue
            background={`var(--beige-to-beige)`}
          >
            <SectionHeader
              reportType={"customers"}
              title={"Earning top marks from members"}
              intro={
                "BCBSRI members rewarded our focus on their health and well-being by giving us top marks on multiple surveys."
              }
            />
            <NestedContainer
              padded
              paddedLast
            >
              <div style={{ margin: "-5rem auto 0", maxWidth: "64rem" }} className="testimonial-mobile">
                <TestimonialCallout
                  unstyled
                  quote="We’re committed to helping our members get the most from their health plan, and we are grateful to hear that we are meeting their needs."
                  author="Martha Wofford"
                  role="President & CEO, BCBSRI"
                />
              </div>
            </NestedContainer>
            <NestedContainer
              styleName="customers-five-star"
              border={`thick-grey`}
              background={`var(--white)`}
              padded
            >
              <div className="customers-five-star__intro-section">
                <h3>Medicare plans rated 5-Stars two years in a row</h3>
                <p>BCBSRI—and our members—did it again. As a result of member feedback, all of our 2023 Medicare Advantage plans earned the highest rating from the Centers for Medicare and Medicaid Services (CMS)—5-out-of-5 Stars—for the second consecutive year.* BCBSRI is the only Rhode Island health insurer to receive 5-out-of-5 Stars for all our Medicare Advantage plans.</p>
                <p className="pb-2 footnotes"><small><i>
                *Rated 5-Stars by CMS in 2022 and 2023. 5-Star ratings can be found on www.medicare.gov. Every year, Medicare evaluates plans based on a 5-Star rating system.</i></small></p>
              </div>
              <Row6633
                reverse
              >
                <div>
                  <span class="anchor" id="5-stars-award"></span>
                  <h4 className="header-color--orange">We’re proud to say we received 5-Stars in the following member-focused categories:</h4>
                  <div className="o-divider o-divider-gray-100"></div>
                  <ul className="customers-bullet bullet__list">
                    <li className="bullet__list-items">Customer service</li>
                    <li className="bullet__list-items">Staying healthy: Screening and tests</li>
                    <li className="bullet__list-items">High levels of member retention</li>
                  </ul>
                  <p>
                    Members enrolled in 5-Star plans generally experience lower out-of-pocket costs, high member satisfaction, better health outcomes, and best-in-class service.
                  </p>
                  <p>
                    This two-year achievement recognizes our ability to sustain a level of excellence that merits the highest rating. Nationally, only 11% of plans earned 5-Stars for 2023 as compared with 16% of plans for 2022.
                  </p>
                </div>
                <div>
                  <StaticImage
                    src="../images/customers/2023-five-star-graphic.jpg"
                    height={438}
                    layout="constrained"
                    className="rounded"
                  />
                </div>
              </Row6633>
            </NestedContainer>
          </FullWidthContainer>
          <FullWidthContainer
            noVerticalPad
            background={`var(--color-2)`}
          >
            <NestedContainer
              className="invert-char-color"
              padded
            >
              <Row6633
                reverse
              >
                <div>
                  <StaticImage
                    src="../images/customers/jd-power-plaque.png"
                    height={438}
                    layout="constrained"
                    className="rounded jd-power-plaque"
                  />
                </div>
                <div>
                  <span class="anchor" id="jd-power-award"></span>
                  <h3 className="invert-header-color left">#1 in member satisfaction again—J.D. Power</h3>
                  <p>Our members have rated BCBSRI #1 in member satisfaction among commercial health plans in the Northeast region in the J.D. Power 2022 U.S. Commercial Member Health Plan Satisfaction Study—for the second year in a row.</p>
                  <p>This year, BCBSRI was ranked highest across all factors measured:</p>
                  <ul className="customers-bullet bullet__list">
                    <li className="bullet__list-items">Customer service</li>
                    <li className="bullet__list-items">Coverage and benefits</li>
                    <li className="bullet__list-items">Cost</li>
                    <li className="bullet__list-items">Information and communications</li>
                    <li className="bullet__list-items">Provider choice</li>
                    <li className="bullet__list-items">Billing and payment</li>
                  </ul>
                  <p>
                    Not only did we rank #1 again, but we also got better! Our score improved more than 27 points overall from last year’s top performance and more than 44 points compared to the regional benchmark.
                  </p>
                  <p>
                    <small><i>For J.D. Power 2022 award information, visit <a href="https://www.jdpower.com/business/awards" target="_blank" rel="noreferrer" className="link-white">jdpower.com/awards</a>.</i></small>
                  </p>
                </div>
              </Row6633>
            </NestedContainer>
          </FullWidthContainer>
        </div>
        <div>
          <FullWidthContainer
            noVerticalPad
            topBlockBlue
            background={`var(--white-to-beige)`}
          >
            <NestedContainer
              border={`thick-grey`}
              background={`var(--white)`}
              padded
              hasShadow
            >
              <Row6633
                verticalCenter
              >
                <div>
                  <RowEqual
                    className={"wrap-early"}
                  >
                    <StaticImage
                      src="../images/customers/NCQA-rating.jpg"
                      layout="constrained"
                      className="rounded"
                      alt=""
                    />
                    <div>
                      <h3 className="left">High quality rating from NCQA for 8 years</h3>
                      <p>For the eighth year, BCBSRI has earned a 4.5 out of 5 rating on our preferred provider organization (PPO) products from the National Committee for Quality Assurance (NCQA), recognizing our commitment to service and clinical quality. The rating of BCBSRI PPO products is part of the Private Health Insurance Plan Ratings for 2022. This makes BCBSRI one of only 14 plans in the country to have received a score of 4.5 or above.</p>
                    </div>
                  </RowEqual>
                </div>
                <div className="sidebar__align-center">
                  <StaticImage
                    src="../images/customers/NCQA.svg"
                    height={438}
                    layout="constrained"
                    className="rounded ncqa-image"
                  />
                  <StatContainer>
                    <h5 className="header-color--orange">RATING</h5>
                    <Stat
                      stat={"4.5/5"}
                    />
                    <h5 className="header-color--orange">ranking</h5>
                    <Stat
                      percentage
                      stat={"4"}
                      label={"top"}
                      description={"out of 331 PPO plans"}
                    />
                  </StatContainer>
                </div>
              </Row6633>
            </NestedContainer>
          </FullWidthContainer>
        </div>
        <div id="making-life-easier-for-medicare-members">
          <FullWidthContainer
            noBottomPad
            bottomBlockBlue
            background={`var(--white-to-orange-white)`}
          >
            <SectionHeader
              reportType={"customers"}
              title={"Making life easier for Medicare members"}
              intro={
                "To help our Medicare Advantage members stay active and independent, we offer important benefits that go beyond providers’ offices and pharmacies. Our members can join a gym, get rides to senior centers, and take classes at our stores—all for $0. Here is a look at other benefits that help older adults live their best lives."
              }
            />
            <NestedContainer
              border={`thick-grey`}
              background={`var(--white)`}
              padded
              hasShadow
            >
              <Row6633
                reverse
                verticalCenter
              >
                <div>
                  <StaticImage
                    src="../images/customers/fairy-gardens.jpg"
                    height={438}
                    layout="constrained"
                    className="rounded"
                  />
                </div>
                <div>
                  <StaticImage
                    src="../images/customers/icon-fairy-garden.svg"
                    alt="Fairy Garden"
                  />
                  <h3 className="left">Help around the house—and in the fairy garden</h3>
                  <span class="anchor" id="making-life-easier-for-medicare-members-anchor"></span>
                  <p>
                    In 2022, we introduced a household help benefit that provides Medicare Advantage members with up to 60 hours of in-home daily living assistance for $0. Members can use this service for everything from light cleaning to help with technology to grocery shopping. They can even schedule a visit to take a walk or watch a movie.
                  </p>
                </div>
              </Row6633>
              <div style={{ margin: "4rem auto 1rem" }} className="testimonial-mobile">
                <TestimonialCallout
                  styled
                  quote="With the household help benefit, someone comes to vacuum, dust, and weed the fairy garden I created with my granddaughter. I don’t have to lean on my daughter for help. I appreciate that Blue Cross is really thinking about our quality of life and helping us maintain as much dignity as we can."
                  author="Linda Kivisto"
                  role="Medicare Advantage member"
                  url="https://rhodeahead.com/medicare/memberperks/your-plan-can-help-fairy-gardens-and-more"
                  linkText="Learn more about Linda"
                  fillColor="#BC4506"
                />
              </div>
            </NestedContainer>
          </FullWidthContainer>
          <FullWidthContainer
            noVerticalPad
            background={`var(--color-2)`}>
            <NestedContainer
              className="invert-char-color"
              padded
            >
              <Row6633
                doubleGap
                verticalCenter
              >
                <div>
                  <StaticImage
                    src="../images/customers/ronna-smith.jpg"
                    height={438}
                    layout="constrained"
                    className="rounded"
                  />
                </div>
                <div>
                  <StaticImage
                    src="../images/customers/icon-team-cares.svg"
                    alt="Fairy Gardens"
                  />
                  <h3 className="invert-header-color left">A team that cares</h3>
                  <p>
                    Having Medicare and Medicaid can be confusing. That’s why our BlueRI for Duals (HMO D-SNP) members have a whole team to help, led by a health navigator. Navigators help our members get the most from their plan, fill out required forms for additional benefits, and work with their doctors.
                  </p>
                </div>
              </Row6633>
              <div style={{ marginTop: "4rem" }} className="testimonial-mobile">
              <TestimonialCallout
                styled
                quote="My health navigator, Anna, has helped me with multiple sclerosis, cancer, and depression. She helps me find specialists, get in touch with doctors if I’m not getting far enough on my own, and anything else I need. I love her. She is on top of everything."
                author="Ronna Smith"
                role="BlueRI for Duals member"
                url="https://rhodeahead.com/medicare/health/right-plan-ronna"
                linkText="Read Ronna's story"
                fillColor="#BC4506"
              />
              </div>
            </NestedContainer>
          </FullWidthContainer>
          <FullWidthContainer
            noVerticalPad
            topBlockBlue
            background={`var(--white-to-beige)`}
          >
            <NestedContainer
              border={`thick-grey`}
              background={`var(--white)`}
              padded
              hasShadow
            >
              <Row6633
                verticalCenter
              >
                <div>
                  <RowEqual
                    className={"wrap-early"}
                  >
                    <div>
                      <h3 className="left">Grocery deliveries to address food insecurity</h3>
                      <p>Food is health. For our BlueRI for Duals (HMO D-SNP) members who qualify, we are now delivering groceries at least once a month, in partnership with Millonzi Fine Catering, a proud Rhode Island company.  The groceries include prepared foods as well as fresh local produce. At Thanksgiving, we delivered an entire holiday dinner, including a turkey.
                      </p><p>Additionally, all our Medicare Advantage plans offer free meal deliveries—a week’s worth of food—for those returning home from the hospital.</p>
                    </div>
                    <StaticImage
                      src="../images/customers/grocery-delivery.png"
                      layout="constrained"
                      className="rounded"
                      alt=""
                    />
                  </RowEqual>
                </div>
                <div className="sidebar__align-center">
                  <StaticImage
                    src="../images/customers/icon-grocery-deliveries.svg"
                    height={438}
                    layout="constrained"
                    className="rounded stats-icon"
                  />
                  <StatContainer>
                    <h5 className="header-color--orange">GROCERY DELIVERIES IN 2022</h5>
                    <div className="o-divider o-divider-gray-100"></div>
                    <Stat
                      stat={"19,253"}
                    />
                  </StatContainer>
                </div>
              </Row6633>
            </NestedContainer>
          </FullWidthContainer>
        </div>
        <div id="keeping-employers-moving-forward">
          <FullWidthContainer
            noBottomPad
            background={`var(--beige-to-white)`}
          >
            <SectionHeader
              reportType={"customers"}
              title={"Keeping employers moving forward"}
              intro={
                "Throughout 2022, we guided employers through future trends as well as current steps they could take to make the most of the health insurance plans they offer to their employees. Among the many challenges they face are the impacts of healthcare on their business, like the rising costs of medications, and conditions that affect their employees, like diabetes and behavioral health. "
              }
            />
            <NestedContainer
              className={'customers__leading-change'}
              padded
            >
              <Row6633
                doubleGap
                verticalCenter
              >
              <div>
                  <StaticImage
                    src="../images/customers/leading-change.png"
                    height={438}
                    layout="constrained"
                    className="rounded"
                  />
                </div>
                <div>
                  <h3 className="left">Leading Change</h3>
                  <p>
                  Through the Leading Change blog and other ongoing communications, BCBSRI has brought a fresh perspective to topics such as Rhode Island’s behavioral health crisis, innovative ways to reduce medical costs, and new options in retiree benefits, always looking for ways employers can help both their business and their employees.
                  </p>
                  <StatContainer>
                    <Stat
                      percentage
                      stat={"97"}
                      description={"of our employer group customers chose to stay with BCBSRI in 2022"}
                    />
                  </StatContainer>
                </div>
              </Row6633>
              <div style={{ margin: "4rem auto 0" }} className="testimonial-mobile testimonial-header">
                <TestimonialCallout
                  styled
                  image={TaraImage}
                  quote={<><a href="https://www.bcbsri.com/employers/change/leadership" target="_blank" className="blog-link">From the Leading Change blog</a><br></br>&ldquo;The diabetes problem in the United States is significant, but there are solutions that can help your employees enjoy a better quality of life.&rdquo;</>}
                  author="Tara DeMoura"
                  role="Senior Vice President, Employer Markets, BCBSRI"
                />
              </div>
            </NestedContainer>
          </FullWidthContainer>
        </div>
        <div id="raising-mental-health-awareness">
          <FullWidthContainer
            noBottomPad
            bottomBlockBeige
            background={`var(--color-2)`}
            styleName={'pt-4'}
          >
            <SectionHeader
              dark
              reportType={"customers"}
              title={"Raising mental health awareness"}
              intro={
                "Mental health is health, and when you or someone you care about is struggling, it’s imperative to find the right treatment. Besides expanding access to care, including adding new telemedicine options, BCBSRI is raising awareness among our members and helping them navigate to the many programs available to them."
              }
            />
            <NestedContainer
              border={`thick-grey`}
              background={`var(--white)`}
              padded
            >
              <Row6633>
                <div className="customer-take-five-mobile">
                  <h3 className="left">Take 5 for You</h3>
                  <StaticImage
                    src="../images/customers/box-breathing.jpg"
                    height={438}
                    layout="constrained"
                    className="rounded"
                  />
                  <p>
                    Even five minutes can make you feel better. That’s the idea behind
                    {" "}
                    <a
                      href="https://www.rhodeahead.com/take5"
                      target="_blank"
                      rel="noreferrer"
                      className="body-link"
                    >
                      Take 5 for You
                    </a>
                    {" "}
                    —small steps that can help improve your mental, physical, and financial health.
                  </p>
                  <StaticImage
                    src="../images/customers/icon-braver-options.svg"
                    alt="Braver options for children"
                    className="icon-braver-options"
                  />
                  <h3 className="left">A Braver option for children</h3>
                  <p>Beginning in 2022, BCBSRI is the only insurer covering bachelor-degree-level coaches for Braver, a leading provider of exposure therapy for children with anxiety, phobias, and obsessive-compulsive disorder. Read how the program helps children in <a
                      href={`/Brave-Little-Worriers-New-York-Times.pdf`}
                      target="_blank"
                      rel="noreferrer"
                      className="body-link"
                    >The New York Times</a>
                    {" "} and {" "}
                    <a
                      href={`/Rhode-Island-health-tech-startup-aims-to-reach-Boston-Globe.pdf`}
                      target="_blank"
                      rel="noreferrer"
                      className="body-link"
                    >The Boston Globe</a>.</p>
                </div>
                <div className="customer-take-five-mobile">
                  <h3 className="left">More help for more Rhode Islanders</h3>
                  <p>
                    BCBSRI is promoting increased services for many groups who need behavioral healthcare:
                  </p>
                  <ul className="customers-bullet bullet__list">
                    <li className="bullet__list-items">Children and adolescents can get help with urgent appointments now available through several providers.</li>
                    <li className="bullet__list-items">Teens and their families learn coping skills for self-harm and other issues through Mindful Teen, a Bradley Hospital program.</li>
                    <li className="bullet__list-items">Adults and children now can receive care for eating disorders through BeCollaborative.</li>
                    <li className="bullet__list-items">Women’s Medicine Collaborative at Lifespan integrates behavioral health into primary care.</li>
                    <li className="bullet__list-items">Seniors with a BCBSRI Medicare Advantage plan now can take part in the Butler Hospital’s HealthPath program and its intensive, community-based, wrap-around services.</li>
                  </ul>
                  <a style={{
                      display: "block",
                      marginTop: "1rem",
                      lineHeight: "140%",
                      fontSize: "1rem",
                    }}
                    className="colored-link"
                    href="https://www.bcbsri.com/individual/member/mentalhealth"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Find a simple, action-oriented list of programs
                    <IconGridArrow fill={'#BC4506'} />
                  </a>
                </div>
              </Row6633>
            </NestedContainer>
          </FullWidthContainer>
        </div>
        <div id="helping-members-face-to-face">
          <FullWidthContainer
            background={`var(--white-to-beige)`}
          >
            <SectionHeader
              reportType={"customers"}
              title={<><div className="pb-2">Helping members face-to-face</div></>}
            />
            <NestedContainer
              border={`thick-grey`}
              background={`var(--white)`}
              padded
              paddedLast
            >
              <Row6633
                verticalCenter
              >
                <div>
                  <StaticImage
                    src="../images/customers/store-irene.jpg"
                    height={438}
                    layout="constrained"
                    className="rounded"
                  />
                  <a style={{
                      display: "block",
                      marginTop: "2rem",
                      lineHeight: "140%",
                    }}
                    className="colored-link"
                    href="https://rhodeahead.com/medicare/health/irene-its-more-store"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Read more about Irene’s experience at the store
                    <IconGridArrow fill={'#BC4506'} />
                  </a>
                </div>
                <div>
                  <div className="sidebar__align-center">
                    <h5 className="header-color--orange">events held in 2022</h5>
                    <div className="o-divider o-divider-gray-100"></div>
                    <StatContainer>
                      <Stat
                        stat={"3,794"}
                      />
                    </StatContainer>
                  </div>
                  <p>
                    In 2022, members visited the four Your Blue Store<sup>SM</sup> locations and events more than 70,000 times. In addition to our brick-and-mortar locations, we met members where they were—in senior housing for cooking demos, in local parks for outdoor fitness classes, and on our bus for help with their plans.
                  </p>
                </div>
              </Row6633>
              <div style={{ margin: "2rem auto 4rem" }} className="testimonial-mobile">
                <TestimonialCallout
                  styled
                  quote="I didn’t know how important choosing a Blue Cross plan would be for my life. The free store classes are the best thing ever. To me, Blue Cross represents optimism, support, and community—and that’s what I always find at Your Blue Store."
                  author="Irene Gambuto"
                  role="Medicare Advantage member"
                />
              </div>
            </NestedContainer>
          </FullWidthContainer>
        </div>
        <div id="encouraging-our-diversity">
          <FullWidthContainer
            styleName={'pt-3'}
            background={`var(--white-to-orange)`}
          >
            <SectionHeader
              reportType={"customers"}
              title={"Encouraging our diversity"}
              intro={
                "As one of the state’s largest employers, we understand that when it comes to diversity, inclusion, equity, and belonging, we can be a model and a resource for the greater Rhode Island community. Our employee business resource groups (EBRGs) led the way in 2022."
              }
            />
            <NestedContainer
              styleName=""
              className="invert-char-color"
              background={`var(--color-2)`}
              padded
            >
              <Row6633
                verticalCenter
              >
                <div>
                  <StaticImage
                    src="../images/customers/pride-event.png"
                    height={438}
                    layout="constrained"
                    className="rounded"
                  />
                </div>
                <div>
                  <h3 className="invert-header-color left">In the community</h3>
                  <ul className="customers-bullet bullet__list">
                    <li className="bullet__list-items">Military Services organized a volunteer effort in partnership with Meals on Wheels of Rhode Island, resulting in 330 signed and personalized cards for veterans.</li>
                    <li className="bullet__list-items">Latin@Link and Rising Talent at Blue joined College Visions to assemble care packages to support first generation college students and help them conquer final exams and end-of-semester projects.</li>
                    <li className="bullet__list-items">Blue Pride hosted a booth at the Rhode Island Pride Festival and shared critical information regarding our Safe Zone program.</li>
                  </ul>
                </div>
              </Row6633>
              <Row6633>
                <div>
                  <h3 className="left invert-header-color pt-2">In the workplace</h3>
                  <ul className="customers-bullet bullet__list">
                    <li className="bullet__list-items">Black Council @ Blue organized a program highlighting racial health disparities facing the Black community and offered suggestions for empowering healthy living.</li>
                    <li className="bullet__list-items">Disability Inclusion Network hosted the Autism Project to discuss misconceptions surrounding autism and ways to support people with autism.</li>
                    <li className="bullet__list-items">Parents @ Work invited Thuba Nguyen to talk about her children’s book and tools parents can use to instill multiculturism in their children.</li>
                  </ul>
                </div>
                <div className="pt-4">
                  <div className="customers__podcast-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 29">
                      <path
                        d="M9 23.951V27H7a1 1 0 0 0 0 2h6a1 1 0 0 0 0-2h-2v-3.049A10 10 0 0 0 20 14v-1a1 1 0 0 0-2 0v1a8 8 0 0 1-16 0v-1a1 1 0 0 0-2 0v1a10 10 0 0 0 9 9.951zM4.34 4c-.115.325-.201.66-.258 1H6.5a.5.5 0 0 1 0 1H4v1h2.5a.5.5 0 0 1 0 1H4v6a6 6 0 1 0 12 0V8h-2.5a.5.5 0 0 1 0-1H16V6h-2.5a.5.5 0 0 1 0-1h2.417a5.949 5.949 0 0 0-.259-1H13.5a.5.5 0 0 1 0-1h1.7A6 6 0 0 0 4.8 3h1.7a.5.5 0 0 1 0 1H4.34z"
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        fill="#D04A02"
                      />
                    </svg>
                  </div>
                  <h4 className="left pt-1 header-normal header-benton invert-header-color">Listen to our employees discuss Juneteenth</h4>
                  <a
                    href="https://www.buzzsprout.com/1977033/10772569-bonus-juneteenth-with-guest-aneesah-cameron"
                    className="btn btn--orange"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span>listen to podcast</span>
                  </a>
                </div>
              </Row6633>
            </NestedContainer>
            <NestedContainer
              padded
              className={'center-logo-callouts'}
            >
              <Row6633>
                <div>
                  <RowEqual>
                    <div className="customers__work-place">
                      <StaticImage
                        src="../images/customers/best.png"
                        height={438}
                        layout="constrained"
                      />
                      <h4>2022 Corporate Equality Index</h4>
                      <p>Human Rights Campaign Foundation</p>
                      <a
                      href="https://www.hrc.org/resources/corporate-equality-index"
                      className="btn btn--orange"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span>Learn More</span>
                    </a>
                    </div>
                    <div className="customers__work-place">
                      <StaticImage
                        src="../images/customers/DEI-Award.png"
                        height={438}
                        layout="constrained"
                      />
                      <h4>2022 Disability Equality Index</h4>
                      <p>American Association of People with Disabilities (AAPD) and Disability:IN<sup>®</sup></p>
                      <a
                      href="https://disabilityin.org/what-we-do/disability-equality-index/"
                      className="btn btn--orange"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span>Learn More</span>
                    </a>
                    </div>
                  </RowEqual>
                </div>
              </Row6633>
            </NestedContainer>
            <NestedContainer
              border={`thick-grey`}
              background={`var(--white)`}
              padded
              hasShadow
            >
              <Row6633
                verticalCenter
              >
                <div>
                  <StaticImage
                    src="../images/customers/scott.jpg"
                    height={438}
                    layout="constrained"
                    className="rounded"
                  />
                </div>
                <div>
                  <h3 className="left">Self ID</h3>
                  <p>
                    BCBSRI encourages associates to self-identify, including ethnicity, gender identity, and sexual orientation, which we will expand to members in the coming year.
                  </p>
                </div>
              </Row6633>
              <div style={{ margin: "4rem auto 1rem" }} className="testimonial-mobile">
                <TestimonialCallout
                  styled
                  quote="Every day when I come to work, I feel like I can be my authentic self and live openly as an employee at BCBSRI."
                  author="Scott Gowrie"
                  role="Corporate Social Responsibility Specialist, BCBSRI"
                />
              </div>
            </NestedContainer>
          </FullWidthContainer>
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;