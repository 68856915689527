import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import "./gallery-slider.css"

const GallerySlider = ({
}) => {
  return (
    <div className="gallery-slider">
      <div className="gallery-slider__slides">
        <StaticImage alt="" height={315} layout="fixed" src="../../images/gallery/slide-community-01.jpg"/>
        <StaticImage alt="" height={315} layout="fixed" src="../../images/gallery/slide-community-02.jpg" />
        <StaticImage alt="" height={315} layout="fixed" src="../../images/gallery/slide-community-13.jpg" />
        <StaticImage alt="" height={315} layout="fixed" src="../../images/gallery/slide-community-04.png" />
        <StaticImage alt="" height={315} layout="fixed" src="../../images/gallery/slide-community-05.jpg" />
        <StaticImage alt="" height={315} layout="fixed" src="../../images/gallery/slide-community-06.jpg" />
        <StaticImage alt="" height={315} layout="fixed" src="../../images/gallery/slide-community-07.jpg" />
        <StaticImage alt="" height={315} layout="fixed" src="../../images/gallery/slide-community-08.jpg" />
        <StaticImage alt="" height={315} layout="fixed" src="../../images/gallery/slide-community-09.jpg" />
        <StaticImage alt="" height={315} layout="fixed" src="../../images/gallery/slide-community-10.jpg" />
        <StaticImage alt="" height={315} layout="fixed" src="../../images/gallery/slide-community-11.jpg" />
        <StaticImage alt="" height={315} layout="fixed" src="../../images/gallery/slide-community-12.jpg" />
        <StaticImage alt="" height={315} layout="fixed" src="../../images/gallery/slide-community-03.jpg" />
      </div>
      <div className="gallery-slider__cta">
        Scroll
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
          <path d="M12 8c0 .2-.1.4-.2.5l-4.4 5.2c-.3.3-.8.4-1.1.1-.3-.2-.4-.7-.1-1l4-4.7-4-4.8c-.3-.4-.2-.8.1-1.1.3-.3.8-.2 1.1.1l4.4 5.2c.1.1.2.3.2.5z" fill="currentColor" />
        </svg>
      </div>
    </div>
  );
}

export default GallerySlider
