import React, { useState } from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import NestedContainer from "../nested-container/nested-container";
import ArrowCircle from "../../images/icons/Icon-Circle-Arrow-Dark-Gray.svg";
import ArrowCircleInvert from "../../images/icons/Icon-Circle-Arrow-Dark-Gray-Invert.svg";
import "./section-callout.css";

/*
  Usage – must include the following params when called.
  - url: A string for the url.
  - title: A string for the title.
  - content: A string for content.

  Example:
  <SectionCallout
    url="/financials",
    title="By The Numbers Financials",
    content="content"
  />
*/

const SectionCallout = ({ url, title, content }) => {
  const [over, setOver] = useState(false);

  return (
    <section className="section-callout">
        <NestedContainer border="none" hasShadow>
          <Link
            to={url}
            onMouseOver={() => setOver(true)}
            onMouseOut={() => setOver(false)}
          >
            <div className="section-callout__inner-container">
              <h2 className="h3">{title}</h2>
              {content && (<p>{content}</p>)}
              <img src={over ? ArrowCircleInvert : ArrowCircle} alt="arrow" />
            </div>
          </Link>
        </NestedContainer>
    </section>
  )
}

SectionCallout.defaultProps = {
  url: ``,
  title: ``,
  content: ``,
};

SectionCallout.propTypes = {
  url: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.string
};

export default SectionCallout;
