import * as React from "react";
// Global Layout

import Layout from "../components/layout/layout";
import { Link } from "gatsby";

// Components
import FullWidthContainer from "../components/full-width-container/full-width-container";
import NestedContainer from "../components/nested-container/nested-container";
import RowEqual from "../components/row/row-equal";
import SectionHeader from "../components/section-header/section-header";

// Images
import iconArrow from "../images/icons/icon-arrow-circle-right-thicker-dark-blue.svg";

// Stylesheets
import "./financials.css";

const FinancialsPage = () => {
  return (
    <Layout pageTitle="2022 BCBSRI Mission Report - Financial Results">
      <div className="financials-page">
        <FullWidthContainer background={`var(--beige)`}>
          <NestedContainer>
          <div className="hero__home">
            <img src={iconArrow} alt="" />
            <Link className="hero__home-link" to="/">
              Home
            </Link>
          </div>
            <SectionHeader
              reportType={"financials"}
              title={"2022 Financial Results"}
              intro={
                "Blue Cross & Blue Shield of Rhode Island realized an after-tax net gain of $21 million in 2022. In part this reflects greater-than-expected enrollment in health plans throughout the year. This allows our not-for-profit company to reinvest in serving members and the community, addressing needs in areas such as food security, housing, and health equity."
              }
            />
          </NestedContainer>
          <NestedContainer
            className="container--financials"
            border={`thick-grey`}
            background={`var(--white)`}
            padded
          >
            <RowEqual divider>
              <div className="financials-number-block">
                <h2>
                  <sup>$</sup>21,043,000
                </h2>
                <span>Overall Profit/Loss</span>
              </div>
              <div>
                <h5 className="left">Overall Profit/Loss</h5>
                <p>
                  This positive financial result allowed us to contribute $21 million into reserves, money we set aside for the protection of all our members to pay future claims. This helped to offset a reduction in the reserves due to investment declines.
                </p>
              </div>
            </RowEqual>
            <div>
              <RowEqual divider>
                <div className="financials-number-block">
                  <h2>
                    <sup>$</sup>408,955,000
                  </h2>
                  <span>Statutory Reserves as of 12/31/2022</span>
                </div>
                <div>
                  <h5 className="left">Statutory Reserves</h5>
                  <p>
                    In 2022, statutory reserves that we held for the protection of our members totaled almost $409 million at year-end. This reflects a reduction of $25.7 million due to investment declines.
                  </p>
                </div>
              </RowEqual>
            </div>
          </NestedContainer>
          <NestedContainer
            className="container--financials"
            border={`thick-grey`}
            background={`var(--white)`}
            padded
          >
            <RowEqual divider>
              <div className="financials-number-block">
                <div>
                  <h2 className="heading-dark">
                    <sup>$</sup>1,883,598,000
                  </h2>
                    <span>Premium Revenue</span>
                  <div className="financials-plus-sign">+</div>
                </div>
                <div className="mb-4">
                  <h2 className="heading-dark">
                    <sup>$</sup>7,028,000
                  </h2>
                  <span>Investment Revenue</span>
                </div>
                <div className="financials-number-block">
                  <h2>
                    <sup>$</sup>1,890,626,000
                  </h2>
                  <span>Total Revenue</span>
                </div>
              </div>
              <div>
                <h5 className="left">Total Revenue</h5>
                <p>
                  In 2022, we recorded almost $1.9 billion in premiums from members (individuals and employers). We also recorded a $7 million gain on investment revenue.
                </p>
              </div>
            </RowEqual>
          </NestedContainer>
          <NestedContainer
            className="container--financials"
            border={`thick-grey`}
            background={`var(--white)`}
            padded
          >
            <RowEqual divider>
              <div className="financials-number-block">
                <div>
                  <h2 className="heading-dark">
                    <sup>$</sup>1,631,116,000
                  </h2>
                  <span>Payments to Providers</span>
                  <div className="financials-plus-sign">+</div>
                </div>
                <div>
                  <h2 className="heading-dark">
                    <sup>$</sup>237,857,000
                  </h2>
                  <span>Administrative Expenses</span>
                  <div className="financials-plus-sign">+</div>
                </div>
                <div className="mb-4">
                  <h2 className="heading-dark">
                    {" "}
                    <sup>$</sup>610,000
                  </h2>
                  <span>Other Expenses</span>
                </div>
                <div className="financials-number-block">
                  <h2>
                    <sup>$</sup>1,869,583,000
                  </h2>
                  <span>Total Expenses</span>
                </div>
              </div>
              <div>
                <h5 className="left">Total Expenses</h5>
                <p>
                  From total revenue, more than $1.6 billion was used for medical and dental claims for our members. This amount accounted for 86.6% of premium revenue collected. The company spent almost $237.9 million (including premium taxes) on expenses to support the core operations of our business, or 12.6% of premiums.
                </p>
              </div>
            </RowEqual>
          </NestedContainer>
          <NestedContainer
            className="container--financials mb-6"
            border={`thick-grey`}
            background={`var(--white)`}
            padded
          >
            <RowEqual divider>
              <div className="financials-number-block">
                <h2>
                  <sup>$</sup>21,043,000
                </h2>
                <span>Net Gain</span>
              </div>
              <div>
                <h5 className="left">Net Gain</h5>
                <p>
                  Overall, total revenue stood at just over $1.89 billion, and expenses were nearly $1.87 billion in 2022, resulting in a net gain of just over $21 million.
                </p>
              </div>
            </RowEqual>
          </NestedContainer>
          <NestedContainer className="disclaimer">
            <p className="note">
              <small><i>NOTE: Numbers may not add up due to rounding.</i></small>
            </p>
            <p className="note">
              This formulation represents unaudited results utilizing Statutory Accounting Principles. The Blue Cross and Blue Shield Association licenses Blue Cross & Blue Shield of Rhode Island to offer certain products and services under the Blue Cross and Blue Shield brand names. Blue Cross & Blue Shield of Rhode Island is an independent organization governed by its own Board of Directors and solely responsible for its own debts and other obligations. Neither the Association nor any other organization using the Blue Cross and Blue Shield brand names acts as a guarantor of Blue Cross & Blue Shield of Rhode Island’s obligations. A copy of Blue Cross & Blue Shield of Rhode Island’s most recent financial statements is available at the state{" "}
              <a
                href="https://dbr.ri.gov/divisions/insurance/financial.php"
                target="_blank"
                rel="noreferrer"
              >
                Department of Business Regulation
              </a>
              .
            </p>
          </NestedContainer>
        </FullWidthContainer>
      </div>
    </Layout>
  );
};

export default FinancialsPage;
