import * as React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import "./hero.css";
import iconArrow from "../../images/icons/icon-arrow-circle-right-thicker-white.svg";
import CircleIndicator from "../circle-indicator/circle-indicator";

/*
  Usage – takes title and intro strings. Inner is a boolean that defaults to
  false.  When true, different styles are applied for the inner pages.
  Community, partners and customers are booleans that default to false.  When
  these are true, the corresponding "Explore Report" menu items display.
  The reportType defaults to blank or can be passed "community", "partners", or
  "customers" to assign different background colors.
  The children prop is used to place a full <StaticImage/> component, since
  StaticImage cannot be dynamically passed a URL as a prop.

  <Hero
    title="Mission Report"
    intro="This is a great intro"
    inner
    community
    partners
  >
    <StaticImage src="../images/stock.jpg" alt="hello" />
  </Hero>

*/

const Hero = ({
  title,
  intro,
  inner,
  reportType,
  community,
  partners,
  customers,
  children,
}) => (
  <div className={`${inner ? "hero hero--alternate" : "hero"} ${reportType}`}>
    <div className="hero__cta">
      <CircleIndicator
        xPosition="left"
        yPosition="bottom"
        title="scroll"
        button={true}
      />
    </div>
    <div className="hero__grid">
      <div className="hero__content">
        {!!inner ? (
          <div className="hero__home">
            <img src={iconArrow} alt="" />
            <Link className="hero__home-link" to="/">
              Home
            </Link>
          </div>
        ) : (
          <div className="hero__year">2022</div>
        )}
        <h1 className="hero__title">{title}</h1>
        <div className="hero__blurb">
          <p>{intro}</p>
        </div>
      </div>
      <div className={`hero__image ${reportType}`}>{children}</div>
    </div>
    <div className="hero__explore">
      <div className="hero__explore-header">Explore Report</div>
      <ul>
        {!!community && (
          <li>
            <Link
              className="hero__explore-link hero__explore-link--community"
              to="/community"
            >
              Community
              <span>view section</span>
            </Link>
          </li>
        )}
        {!!customers && (
          <li>
            <Link
              className="hero__explore-link hero__explore-link--customers"
              to="/customers"
            >
              Customers
              <span>view section</span>
            </Link>
          </li>
        )}
        {!!partners && (
          <li>
            <Link
              className="hero__explore-link hero__explore-link--partners"
              to="/provider-partners"
            >
              Provider Partners
              <span>view section</span>
            </Link>
          </li>
        )}
      </ul>
    </div>
  </div>
);

Hero.defaultProps = {
  title: "",
  intro: "",
  reportType: "",
  community: false,
  partners: false,
  customers: false,
};

Hero.propTypes = {
  title: PropTypes.string,
  intro: PropTypes.string,
  reportType: PropTypes.oneOf(["community", "partners", "customers"]),
  community: PropTypes.bool,
  partners: PropTypes.bool,
  customers: PropTypes.bool,
};

export default Hero;
