import React from "react";
import NestedContainer from "../nested-container/nested-container";
import RowEqual from "../row/row-equal";
import HandGlobe from "../../images/icons/f-icon-supporting.svg";
import HandHeart from "../../images/icons/icon-people-impacted.png";
import Calendar from "../../images/icons/f-icon-timing.svg";
import DollarSign from "../../images/icons/f-icon-volunteering.svg";
import Tshirt from "../../images/icons/f-icon-fundraising.svg";
import PiggyBank from "../../images/icons/f-icon-investing.svg";

import "./homepage-financial.css";

const HomepageFinancial = () => (
  <div className="homepage-financial">
    <h2>Living Our Mission</h2>
    <NestedContainer background={"var(--color-24)"} border="thicker-blue-alt" hasShadow>
      <RowEqual divider doubleGap styleName={"homepage-financial-grid"}>
        <div className="row-item">
          <div className="homepage-financial__icon">
            <img src={HandGlobe} alt="sample" />
          </div>
          <div className="homepage-financial__number">170</div>
          <div className="homepage-financial__desc">
            organizations supported through in-kind donations, volunteerism, or financial support
          </div>

          <div className="homepage-financial__icon">
            <img src={Calendar} alt="sample" />
          </div>
          <div className="homepage-financial__number">7,600</div>
          <div className="homepage-financial__desc">volunteer hours served</div>

          <div className="homepage-financial__icon">
            <img src={Tshirt} alt="sample" />
          </div>
          <div className="homepage-financial__number">$140,348</div>
          <div className="homepage-financial__desc">
            generated by employee fundraising
          </div>
        </div>

        <div className="row-item">
          <div className="homepage-financial__icon">
            <img src={HandHeart} alt="sample" />
          </div>
          <div className="homepage-financial__number">165,414</div>
          <div className="homepage-financial__desc">
            people impacted by charitable funding or
            <br />
            volunteerism
          </div>
          <div className="homepage-financial__icon">
            <img src={DollarSign} alt="sample" />
          </div>
          <div className="homepage-financial__number">$226,510</div>
          <div className="homepage-financial__desc">
            value of volunteer hours*
          </div>

          <div className="homepage-financial__icon">
            <img src={PiggyBank} alt="sample" />
          </div>
          <div className="homepage-financial__number">$4.1 million+</div>
          <div className="homepage-financial__desc">
            invested by the company through financial or in-kind donations or distributions from the Rhode Island Community Health Fund at the Rhode Island Foundation
          </div>
        </div>
      </RowEqual>
      <div className="homepage-financial__source">
        <p className="mb-0 footnotes"><small><i>*Source: Independent Sector</i></small></p>
      </div>
    </NestedContainer>
  </div>
);

export default HomepageFinancial;
