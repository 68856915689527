import React, { useEffect } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import IconGridArrow from "../IconGridArrow/IconGridArrow";
import TestimonialCallout from "../testimonial-callout/testimonial-callout";
import VideoModal from "../video-modal/video-modal";
import classImg from "../../images/mission_report_video_thumbnail.jpg";
import videoIcon from "../../images/icons/Icon-Play-Video-Blue.svg";
import Topics from "../../../content/topics.yml";

import "./content-grid.css";

const ContentGrid = () => {
  const topics = Topics.reduce(
    (obj, topic) => ({ ...obj, [topic.slug]: topic }),
    {}
  );

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("content-grid__item--scrolled-into-view");
        }
      });
    });

    document
      .querySelectorAll(".content-grid__item")
      .forEach((element) => observer.observe(element));
  });

  const ContentItem = ({ topic, title, url, children }) => (
    <Link to={url} className="content-grid__item__card">
      <div
        className="content-grid__item"
        style={{ "--topic-color": topic.color }}
      >

        <span className="content-grid__item__tag">{topic.slug}</span>
        <div className="content-grid__item__title">{title}</div>
        <div className="content-grid__item__media">{children}</div>
        {url && (
          <div className="content-grid__item__link">
            Read More
              <IconGridArrow fill={topic.color} />
          </div>
        )}
      </div>
    </Link>
  );

  const IllustrationContentItem = ({ title, subtitle, children }) => (
    <div className="content-grid__illustration">
      <div className="content-grid__illustration-image">{children}</div>
      <div className="content-grid__illustration-title">{title}</div>
      <div className="content-grid__illustration-subtitle">{subtitle}</div>
    </div>
  );

  return (
    <div className="content-grid-container pt-none pb-none">
      <div className="content-grid">
        <div>
          <div className="content-grid">
            <div className="content-grid__video">
              <ContentItem
                topic={topics.video}
                title="RI Rising in 2022"
              >
                <VideoModal
                  ariaHideApp={false}
                  posterImage={classImg}
                  videoPlayIcon={videoIcon}
                  videoSource="https://player.vimeo.com/video/814744115?h=3b3bca699e"
                  width={640}
                  height={564}
                  allow="autoplay; fullscreen"
                />
                <a
                    style={{
                      marginTop: "1rem",
                    }}
                    className="colored-link"
                    href="https://player.vimeo.com/video/814744115?h=3b3bca699e"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Watch Video
                    <IconGridArrow fill={'#0079B5'} />
                  </a>
              </ContentItem>
            </div>
          </div>
          <div className="content-grid content-grid-tablet">
            <div className="content-grid-lf-col">
              <ContentItem
                topic={topics.customers}
                title="Raising mental health awareness"
                url="/customers#raising-mental-health-awareness"
              >
                <StaticImage
                  src="../../images/photos/image-07.jpg"
                  layout="constrained"
                />
              </ContentItem>
            </div>
            <div className="content-grid-lf-col">
              <ContentItem
                topic={{
                  slug: "provider partners",
                  url: "/provider-partners",
                  color: "#A20067",
                }}
                title="Addressing prescription drug costs"
                url="/provider-partners#addressing-prescription-drug-costs"
              >
                <StaticImage
                  src="../../images/photos/image-02.jpg"
                  layout="constrained"
                />
              </ContentItem>
            </div>
            <div className="content-grid-lf-col">
              <ContentItem
                topic={{
                  slug: "provider partners",
                  color: "#A20067",
                }}
                title="Value-based care with Prospect"
                url="/provider-partners#promoting-well-care-over-sick-care"
              >
                <StaticImage
                  src="../../images/photos/image-08.jpg"
                  layout="constrained"
                />
              </ContentItem>
            </div>
            <div className="content-grid-lf-col">
              <IllustrationContentItem
                title="19,253"
                subtitle={<><strong>grocery deliveries in 2022</strong></>}>
                <StaticImage
                  src="../../images/icons/icon-grocery-delivery.png"
                  layout="constrained"
                />
              </IllustrationContentItem>
            </div>
            <div className="content-grid-lf-col">
              <ContentItem
                topic={topics.customers}
                title="Help around the house—and in the fairy garden"
                url="/customers#making-life-easier-for-medicare-members"
              >
                <StaticImage
                  src="../../images/photos/image-03.jpg"
                  layout="constrained"
                />
              </ContentItem>
            </div>
          </div>
        </div>
        <div>
          <div className="content-grid content-grid-tablet">
            <div className="content-grid-rt-col">
                <IllustrationContentItem
                  subtitle={<><strong>#1 in member satisfaction again—J.D. Power</strong></>}>
                  <Link to="/customers#jd-power-award" role="link" aria-label="JD Power Award">
                    <StaticImage
                      src="../../images/photos/jd-power-plaque.png"
                      layout="constrained"
                      class="icon-jdpower"
                    />
                  </Link>
                </IllustrationContentItem>
            </div>
            <div className="content-grid-rt-col">
              <ContentItem
                topic={topics.community}
                title="Improving access to safe and affordable housing"
                url="/community#improving-access-to-safe-and-affordable-housing"
              >
                <StaticImage
                  src="../../images/photos/image-04.jpg"
                  layout="constrained"
                />
              </ContentItem>
            </div>
            <div className="content-grid-rt-col">
              <ContentItem
                topic={topics.community}
                title="A focus on fresh food"
                url="/community#bringing-nutritious-foods-to-our-neighbor-anchor"
              >
                <StaticImage
                  src="../../images/photos/image-01.jpg"
                  layout="constrained"
                />
              </ContentItem>
            </div>
            <div className="content-grid-rt-col">
                <IllustrationContentItem
                title="170"
                  subtitle={<><strong>organizations supported through in-kind donations, volunteerism, or financial support</strong></>}>
                  <StaticImage
                    src="../../images/icons/icon-kind-donations.png"
                    layout="constrained"
                  />
                </IllustrationContentItem>
            </div>
            <div className="content-grid-rt-col">
                <TestimonialCallout
                  grid
                  author="Azade Perin-Monterroso"
                  role="Executive Director, Habitat for Humanity of Greater Providence and East Bay"
                  url="/community#giving-back-across-the-state"
                  quote="It's not only about building a home. It's about using the home as a tool to bring the community together. And that's what makes us stronger."
                />
            </div>
            <div className="content-grid-rt-col">
              <ContentItem
                topic={topics.community}
                title="Maternal health and doulas"
                url="/community#using-data-to-drive-health-equity-anchor"
              >
                <StaticImage
                  src="../../images/photos/image-10.jpg"
                  layout="constrained"
                />
              </ContentItem>
            </div>
            <div className="content-grid-rt-col">
              <IllustrationContentItem
                  subtitle={<><strong>Rated 5-Stars by CMS for 2022 and 2023</strong></>}>
                  <Link to="/customers#5-stars-award" role="link" aria-label="JD Power Award">
                    <StaticImage
                      src="../../images/photos/five-stars-award.svg"
                      layout="constrained"
                    />
                  </Link>
                </IllustrationContentItem>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentGrid;
