import React, { useState, useRef } from "react";

// Global Layout
import Layout from "../components/layout/layout";

// Components
import Hero from "../components/hero/hero";
import FullWidthContainer from "../components/full-width-container/full-width-container";
import { StaticImage } from "gatsby-plugin-image";
import SectionHeader from "../components/section-header/section-header";
import NestedContainer from "../components/nested-container/nested-container";
import Row6633 from "../components/row/row-66-33";
import RowEqual from "../components/row/row-equal";
import TestimonialCallout from "../components/testimonial-callout/testimonial-callout";
import VideoModal from "../components/video-modal/video-modal";
import IconGridArrow from "../components/IconGridArrow/IconGridArrow";
import StatContainer from "../components/stat/stat-container";
import Stat from "../components/stat/stat";
import GallerySlider from "../components/gallery-slider/gallery-slider";

// Images
import playIcon from "../images/community/Icon-Play-Video-Green.svg";
import marthaImage from "../images/community/image-martha.png";
import posterImg1 from "../images/community/poster-image-01.png";
import posterImg2 from "../images/community/blue-across-rhode-island.png";
import directActionColor from "../images/community/c-direct-action.jpg";
import directActionMuted from "../images/community/bw-direct-action.png";
import housingNTColor from "../images/community/c-housing-nt.jpg";
import housingNTMuted from "../images/community/bw-housing-nt.png";
import neighborhoodColor from "../images/community/c-neighborhood-builders.jpg";
import neighborhoodMuted from "../images/community/bw-neighborhood-builders.png";
import neighborWorksColor from "../images/community/c-neighborworks.jpg";
import neighborWorksMuted from "../images/community/bw-neighborworks.png";
import pawtucketColor from "../images/community/c-pawtucket.jpg";
import pawtucketMuted from "../images/community/bw-pawtucket.png";
import ricoColor from "../images/community/c-rhode-island-co.jpg";
import ricoMuted from "../images/community/bw-rhode-island-co.png";

//Stylesheets
import "./community.css";
import "./styles.pillar-pages.css";

const CommunityPage = () => {
  const imageDirectAction = useRef(null);
  const imageHousingNT = useRef(null);
  const imageNeighborhood = useRef(null);
  const imageNeighborWorks = useRef(null);
  const imagePawtucket = useRef(null);
  const imageRICO = useRef(null);

  return (
    <Layout pageTitle="2022 BCBSRI Mission Report - Community">
      <div className="community-page">
        <FullWidthContainer
          singleHero
          background={`var(--beige-to-white)`}
        >
          <Hero
            title="Community"
            intro="As Rhode Island's largest local health insurer, BCBSRI has always been rooted in the community. We know that Rhode Islanders' well-being is influenced by where we grow up, play, live, and work. That knowledge drives our efforts to increase health equity, continually building toward healthier and more vibrant communities for everyone."
            reportType="community"
            inner
            customers
            partners
          >
            <StaticImage
              src="../images/community/Community-Hero.jpg"
              alt=""
              loading={"eager"}
              aspectRatio={5 / 2}
              className={"community-hero"}
            />
          </Hero>
        </FullWidthContainer>
        <div id="using-data-to-drive-health-equity">
          <FullWidthContainer
            background={`var(--beige-to-white-to-green)`}
            noBottomPad
          >
            <SectionHeader
              reportType={"community"}
              title={"Using data to drive health equity"}
              intro={
                "Good health is about so much more than what happens at the doctor’s office. At its most basic, it’s about having a safe place to call home and nutritious food to eat every day. Too many Rhode Islanders struggle to meet their basic needs, making it nearly impossible for them to maintain their good health—much less manage a chronic health condition. We are committed to helping change this. BCBSRI is using a data-driven strategy to help ensure all Rhode Islanders can live their healthiest lives."
              }
            />
            <NestedContainer
              padded
              paddedLast
            >
              <div style={{ margin: "-6rem auto 0", maxWidth: "48rem" }} className="testimonial-mobile">
                <TestimonialCallout
                  unstyled
                  quote="Health equity means that everyone has a fair and just opportunity to be as healthy as possible."
                  author={<><span className="quote-single">Robert Wood Johnson Foundation</span></>}
                />
              </div>
            </NestedContainer>
            <NestedContainer
              border={`thick-grey`}
              background={`var(--white)`}
              padded
              hasShadow
            >
              <Row6633
                doubleGap
              >
                <div>
                  <h3 className="left">RI Life Index</h3>
                  <StaticImage
                    src="../images/community/RI-Life-Index.png"
                    height={438}
                    layout="constrained"
                    className="rounded"
                  />
                  <p style={{ marginTop: "1rem", marginBottom: "1rem" }}>A partnership of BCBSRI and the Brown University School of Public Health, the Index reveals trends in Rhode Islanders’ perceptions of their quality of life, community, and other health-related social factors. In 2022, the fourth year of the Index, we saw declines in several categories from 2021.</p>
                  <div className="podcast-cta mobile-space">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 29">
                      <path
                        d="M9 23.951V27H7a1 1 0 0 0 0 2h6a1 1 0 0 0 0-2h-2v-3.049A10 10 0 0 0 20 14v-1a1 1 0 0 0-2 0v1a8 8 0 0 1-16 0v-1a1 1 0 0 0-2 0v1a10 10 0 0 0 9 9.951zM4.34 4c-.115.325-.201.66-.258 1H6.5a.5.5 0 0 1 0 1H4v1h2.5a.5.5 0 0 1 0 1H4v6a6 6 0 1 0 12 0V8h-2.5a.5.5 0 0 1 0-1H16V6h-2.5a.5.5 0 0 1 0-1h2.417a5.949 5.949 0 0 0-.259-1H13.5a.5.5 0 0 1 0-1h1.7A6 6 0 0 0 4.8 3h1.7a.5.5 0 0 1 0 1H4.34z"
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        fill="#479130"
                      />
                    </svg>
                    <span className="podcast-cta__text">
                      Hear community leaders discuss the results
                    </span>
                    <a
                      href="https://www.buzzsprout.com/1977033/11840831-ri-life-index-a-community-conversation"
                      className="btn btn--green"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span>listen to podcast</span>
                    </a>
                  </div>
                </div>
                <div>
                <div className="sidebar__align-center">
                  <h4 className="header-color--green">2022 results<br />compared to 2021</h4>
                  <div className="o-divider o-divider-gray-100"></div>
                  <StatContainer>
                    <Stat
                      stat={"4"}
                      icon
                      tag={"points"}
                      description={"Overall Index"}
                    />
                    <Stat
                      stat={"6"}
                      icon
                      tag={"points"}
                      description={"Economic situation score"}
                    />
                    <Stat
                      stat={"4"}
                      icon
                      tag={"points"}
                      description={"Food security score"}
                    />
                    <Stat
                      stat={"11"}
                      icon
                      tag={"points"}
                      description={"Affordable housing score (since 2020)"}
                    />
                  </StatContainer>
                </div>
                  <a style={{
                      display: "block",
                      marginTop: "1rem",
                      lineHeight: "140%",
                    }}
                    className="colored-link"
                    href="https://www.rilifeindex.org/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    See the declines—and improvements—in Index scores.
                    <IconGridArrow fill={'#479130'} />
                  </a>
                </div>
              </Row6633>
            </NestedContainer>
            <NestedContainer
              padded
            >
              <Row6633
                reverse
                verticalCenter
              >
                <div>
                  <StaticImage
                    src="../images/community/Health-Equity-Report.png"
                    height={438}
                    layout="constrained"
                    className="rounded"
                  />
                </div>
                <div>
                  <h3 className="left">Health Equity Report</h3>
                  <p>
                  Information is essential in our effort to ensure that all BCBSRI members have access to quality and equitable care. Our Health Equity Report includes data from our Rhode Island adult members, and it confirms racial and ethnic disparities in several areas of patient care. As we collect more information from more members in 2023, we’ll use this data to understand underlying inequities and to develop programs that support health and well-being needs and help reduce health disparities in the community.
                  </p>
                  <a
                    className="colored-link"
                    href="https://www.bcbsri.com/sites/default/files/about/HealthEquityReport2022.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    SEE WHERE WE ARE TODAY
                    <IconGridArrow fill={'#479130'} />
                  </a>
                </div>
              </Row6633>
            </NestedContainer>
            <NestedContainer
              className="invert-char-color"
              background={`var(--color-2)`}
              padded
            >
              <Row6633
                verticalCenter
              >
                <div>
                  <h3 className="left invert-header-color">Maternal health and doulas</h3>
                  <span class="anchor" id="using-data-to-drive-health-equity-anchor"></span>
                  <p>Everyone deserves the best pregnancy and childbirth care available, regardless of age, race, ethnicity, or ZIP code. BCBSRI is committed to reducing racial disparities through a multiyear, multifaceted strategy that addresses the full spectrum of maternal care among those at higher risk.</p>
                  <p>BCBSRI began covering doula services for pregnant people in January 2022. Trained and certified doulas provide emotional, physical, and educational support before, during, and after childbirth. Additionally, we’re working with local partners, such as the Urban Perinatal Education Center, to bolster the doula workforce in Rhode Island.</p>
                  <p>We’ve also launched a high-risk maternal care management program, with a goal to reduce the risk of preterm births, low birth weights, and pregnancy-related complications by providing relevant information, tools, and resources to those expecting. We’re building partnerships with ob/gyns and maternal care providers throughout Rhode Island to maximize the effectiveness of the program.</p>
                  <a
                    style={{
                      color: "var(--white)",
                    }}
                    className="colored-link"
                    href="https://www.bcbsri.com/employee/member/maternal"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Understand maternal health benefits
                    <IconGridArrow fill={'#479130'} />
                  </a>
                </div>
                <div>
                <StaticImage
                  src="../images/community/Maternal-Health-Doulas.png"
                  layout="constrained"
                  className="rounded"
                />
              </div>
              </Row6633>
            </NestedContainer>
          </FullWidthContainer>
        </div>
        <div id="improving-access-to-safe-and-affordable-housing">
          <FullWidthContainer
            background={`var(--white-to-green--rev)`}
            noBottomPad
          >
            <SectionHeader
              reportType={"community"}
              title={"Improving access to safe and affordable housing"}
              intro={
                "The RI Life Index guides BCBSRI’s philanthropy, and since 2019, our highest priority has been improving access to safe and affordable housing. 2022 grants build on our existing investments in addressing affordable housing, which total more than $5.27 million since 2019."
              }
            />
            <NestedContainer
              className="community-housing"
              border={`thick-grey`}
              background={`var(--white)`}
              padded
              hasShadow
            >
              <Row6633 doubleGap>
                <div className="mt-sm">
                <span class="anchor" id="improving-access-to-safe-and-affordable-housing-anchor"></span>
                  <h3 className=""> 2022 BlueAngel Community Health Grant recipients</h3>
                  <div className="o-divider o-divider-gray-100"></div>
                  <p>Last year, we marked the 20th anniversary of our BlueAngel Community Health Grants—given through the BCBSRI Community Health Fund at the Rhode Island Foundation—which address critical health issues in Rhode Island. Our 2022 grant recipients are helping our neighbors across the state, from creating affordable, supportive housing for older Rhode Islanders to offering financial education classes in Spanish to providing free legal counsel and advocacy.</p>
                  <div class="community-grants__recipients__grid">
                    <a
                      href="https://daretowin.org/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        onMouseOver={() => {
                          imageDirectAction.current.src = directActionColor;
                        }}
                        onMouseOut={() => {
                          imageDirectAction.current.src = directActionMuted;
                        }}
                        src={directActionMuted}
                        alt="Direct Qction for Rights and Equality"
                        ref={imageDirectAction}
                      />
                    </a>
                    <a
                      href="https://housingnetworkri.org/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        onMouseOver={() => {
                          imageHousingNT.current.src = housingNTColor;
                        }}
                        onMouseOut={() => {
                          imageHousingNT.current.src = housingNTMuted;
                        }}
                        src={housingNTMuted}
                        alt="Housing Network"
                        ref={imageHousingNT}
                      />
                    </a>
                    <a
                      href="https://oneneighborhoodbuilders.org/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        onMouseOver={() => {
                          imageNeighborhood.current.src = neighborhoodColor;
                        }}
                        onMouseOut={() => {
                          imageNeighborhood.current.src = neighborhoodMuted;
                        }}
                        src={neighborhoodMuted}
                        alt="neighborhood builders"
                        ref={imageNeighborhood}
                      />
                    </a>
                    <a
                      href="https://www.ehomeamerica.org/neighborworksbrv"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        onMouseOver={() => {
                          imageNeighborWorks.current.src = neighborWorksColor;
                        }}
                        onMouseOut={() => {
                          imageNeighborWorks.current.src = neighborWorksMuted;
                        }}
                        src={neighborWorksMuted}
                        alt="NeighborWorks"
                        ref={imageNeighborWorks}
                      />
                    </a>
                    <a
                      href="https://www.pcfdevelopment.org/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        onMouseOver={() => {
                          imagePawtucket.current.src = pawtucketColor;
                        }}
                        onMouseOut={() => {
                          imagePawtucket.current.src = pawtucketMuted;
                        }}
                        src={pawtucketMuted}
                        alt="pawtucket central falls development"
                        ref={imagePawtucket}
                      />
                    </a>
                    <a
                      href="https://www.rihomeless.org/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        onMouseOver={() => {
                          imageRICO.current.src = ricoColor;
                        }}
                        onMouseOut={() => {
                          imageRICO.current.src = ricoMuted;
                        }}
                        src={ricoMuted}
                        alt="Rhode Island Coalation to end homelessness"
                        ref={imageRICO}
                      />
                    </a>
                  </div>
                  <div className="mb-show mt-5">
                    <div className="sidebar__align-center">
                      <StaticImage
                        src="../images/community/Logo-BlueAngel.png"
                        alt="BlueAngel"
                        width="227"
                        className="blue-angel-logo"
                      />
                      <StatContainer>
                        <Stat
                          stat={"405,000"}
                          dollarSign
                          description={"in BlueAngel Community Health grant funding"}
                        />
                      </StatContainer>
                      <div className="o-divider o-divider-gray-100"></div>
                    </div>
                    <div className="sidebar__align-left">
                      <h3>A new approach to housing</h3>
                      <p>
                        Through the BlueAngel Community Health Grant program, BCBSRI is contributing to Rhode Island’s launch of the Pay For Success Permanent Supportive Housing Pilot Program, which aims to increase the number of residents who successfully maintain permanent housing. The program seeks to house Rhode Islanders who frequently use healthcare, the justice system, and homeless shelters, helping to reduce financial impacts to those systems and improving the lives of those with exceptionally complex needs.
                      </p>
                    </div>
                    <div className="sidebar__align-left">
                      <h3>Housing is Healthcare</h3>
                      <p>
                        This program will provide innovative financing to catalyze safe and affordable housing in RI.  With support from BCBSRI, the Local Initiative Support Corporation (LISC) will target housing development using an equity lens and increase the availability of affordable units within the state, while continuing to explore the development of effective programs to address homelessness. These include permanent supportive housing and medical respite housing, both of which offer support services to residents. LISC Rhode Island is committed to creating and preserving more than 1,500 affordable units through its partnership with BCBSRI and other funders.
                      </p>
                    </div>
                  </div>
                  <div className="pt-4 grid-2-col-alt">
                    <div>
                      <div className="community-housing">
                        <StaticImage
                          src="../images/community/icon-homelessness.png"
                          alt="homelessness"
                          className="community-housing__icon"
                        />
                        <h3>State of Homelessness</h3>
                        <p>
                          The 2022 State of Homelessness findings were bleak: 1,577 people experienced homelessness during the January 2022 Point in Time count. BCBSRI is a sponsor of the annual count release, conducted by The Rhode Island Coalition to End Homelessness.
                        </p>
                      </div>
                    </div>
                    <div>
                      <div className="community-housing">
                        <StaticImage
                          src="../images/community/icon-aging-place.png"
                          alt="aging in place"
                          className="community-housing__icon"
                        />
                        <h3>Aging in Place</h3>
                        <p>
                          The Aging in Place program from Habitat for Humanity of Rhode Island—Greater Providence and East Bay, with support from BCBSRI, provides home modification, repair, and decluttering services for older Rhode Islanders.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mb-hide">
                  <div className="sidebar__align-center">
                    <StaticImage
                      src="../images/community/Logo-BlueAngel.png"
                      alt="BlueAngel"
                      width="227"
                      className="blue-angel-logo"
                    />
                    <StatContainer>
                      <Stat
                        stat={"405,000"}
                        dollarSign
                        description={"in BlueAngel Community Health grant funding"}
                      />
                    </StatContainer>
                    <div className="o-divider o-divider-gray-100"></div>
                  </div>
                  <div className="sidebar__align-left">
                    <h3>A new approach to housing</h3>
                    <p>
                      Through the BlueAngel Community Health Grant program, BCBSRI is contributing to Rhode Island’s launch of the Pay For Success Permanent Supportive Housing Pilot Program, which aims to increase the number of residents who successfully maintain permanent housing. The program seeks to house Rhode Islanders who frequently use healthcare, the justice system, and homeless shelters, helping to reduce financial impacts to those systems and improving the lives of those with exceptionally complex needs.
                    </p>
                  </div>
                  <div className="sidebar__align-left">
                    <h3>Housing is Healthcare</h3>
                    <p>
                      This program will provide innovative financing to catalyze safe and affordable housing in RI.  With support from BCBSRI, the Local Initiative Support Corporation (LISC) will target housing development using an equity lens and increase the availability of affordable units within the state, while continuing to explore the development of effective programs to address homelessness. These include permanent supportive housing and medical respite housing, both of which offer support services to residents. LISC Rhode Island is committed to creating and preserving more than 1,500 affordable units through its partnership with BCBSRI and other funders.
                    </p>
                  </div>
                </div>
              </Row6633>
            </NestedContainer>
          </FullWidthContainer>
        </div>
        <div id="bringing-nutritious-foods-to-our-neighbor">
          <FullWidthContainer
            noBottomPad
            bottomBlockBlue
            background={`var(--white-to-green)`}
          >
            <SectionHeader
              reportType={"community"}
              title={"Bringing nutritious foods to our neighbors"}
              intro={
                "With food prices rising and pandemic relief programs ending, food insecurity increased significantly in our state in 2022. Nearly one in three households were not able to afford adequate food, according to the RI Life Index. Working with our community partners, we expanded efforts to help our neighbors access the nutritious food they need to thrive."
              }
            />
            <NestedContainer
              className="community-nutritious__video"
              padded
              paddedLast
            >
              <Row6633
                doubleGap
                reverse
              >
                <div>
                  <span class="anchor" id="bringing-nutritious-foods-to-our-neighbor-anchor"></span>
                  <h3 className="pt-1">A focus on fresh food</h3>
                  <p>
                    Through a new partnership with The ELISHA Project, BCBSRI sponsored a 10-month Share Market series. These markets increase the availability of wholesome, culturally relevant foods in low-income communities, with a family of four receiving approximately 25 pounds of nutritious food, including protein, fruits, and vegetables. Our BlueAngel volunteers supported every market, with more than 120 people serving over the ten months.
                  </p>
                  <StatContainer>
                    <Stat
                      stat={"55,000"}
                      plusIcon
                      description={"community members served at 2022 Share Markets"}
                    />
                  </StatContainer>
                </div>
                <div>
                  <VideoModal
                    ariaHideApp={false}
                    posterImage={posterImg1}
                    videoPlayIcon={playIcon}
                    videoSource="https://player.vimeo.com/video/804319687?h=a3250a331c"
                    variant={"customer--large"}
                  />
                  <div style={{marginTop: "0.5rem"}}><small>Courtesy of The ELISHA Project</small></div>
                  <a
                    style={{
                      marginTop: "1rem",
                    }}
                    className="colored-link"
                    href="https://player.vimeo.com/video/804319687?h=a3250a331c"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Watch Video
                    <IconGridArrow fill={'#479130'} />
                  </a>
                </div>
              </Row6633>
            </NestedContainer>
            <NestedContainer
              padded
              paddedLast
            >
              <div>
                <TestimonialCallout
                  styled
                  quote="We recognize the tremendous health benefits of fresh food. People who are struggling are often relegated to eating prepackaged and inexpensive food high in sugar and saturated fats. With its emphasis on keeping Rhode Islanders healthy, Blue Cross is a logical—and welcome—partner for The ELISHA Project."
                  author="George Ortiz"
                  role="Co-Founder, The ELISHA Project"
                />
              </div>
            </NestedContainer>
          </FullWidthContainer>
          <FullWidthContainer
            noVerticalPad
            background={`var(--color-2)`}>
            <NestedContainer
              className="invert-char-color"
              padded
            >
              <Row6633
                reverse
              >
                <div>
                  <StaticImage
                    src="../images/community/meals.png"
                    layout="constrained"
                    className="rounded-alt"
                  />
                </div>
                <div>
                  <RowEqual
                    className={"wrap-early"}
                  >
                    <div>
                      <div>
                        <StaticImage
                          src="../images/community/icon-gobble-games.svg"
                          alt="meals for older adults"
                          className="community-nutritious__icon icon-gobble-games"
                        />
                        <h3 className="invert-header-color">Gobble Games for the Food Bank</h3>
                        <p>
                          BCBSRI associates participated in the 2022 Gobble Games, an annual fundraising competition leading up to Thanksgiving in support of the Rhode Island Community Food Bank. All Gobble Games gifts by associates were matched by BCBSRI.
                        </p>
                      </div>
                      <StatContainer>
                        <Stat
                          stat={"23,000"}
                          darkbg
                          dollarSign
                          description={"total BCBSRI donation"}
                        />
                      </StatContainer>
                    </div>
                    <div>
                      <div>
                        <StaticImage
                          src="../images/community/icon-food-pantry.png"
                          alt="food pantry"
                          className="community-nutritious__icon"
                        />
                        <h3 className="invert-header-color">A new food pantry for students in Providence</h3>
                        <p>
                          During Hunger Awareness Month, BCBSRI partnered with We Share Hope to support a new food pantry at Mt. Pleasant High School. The pantry helped students excel by reducing the burden of food insecurity experienced by more than 50% of the student population.
                        </p>
                      </div>
                      <StatContainer>
                        <Stat
                          stat={"2,250"}
                          darkbg
                          description={"students helped in 2022"}
                        />
                      </StatContainer>
                    </div>
                  </RowEqual>
                </div>
              </Row6633>
              <div style={{opacity: 0.3, marginTop: '3rem'}} className="o-divider o-divider-white-100"></div>
              <Row6633
                verticalCenter
              >
                <div>
                  <div className="pt-1">
                    <StaticImage
                      src="../images/community/icon-bike.png"
                      alt="meals for older adults"
                      className="community-nutritious__icon icon-bike"
                    />
                    <h3 className="invert-header-color">Culturally relevant meals for older adults</h3>
                    <p>
                      Through a Meals on Wheels Rhode Island pilot program, more than 180 participants—most of whom are Hispanic or Latino—had nutritious, culturally relevant daily meals delivered. In addition to this innovative pilot, we were pleased to again support March for Meals’ annual public awareness campaign, which helps raise critical funding to help thousands of homebound older adults in our state.
                    </p>
                  </div>
                </div>
                <div>
                  <div className="podcast-content">
                    <StaticImage
                      src="../images/community/icon-read-more.png"
                      alt="read more"
                      className="community-nutritious__icon"
                    />
                    <h5 className="header-benton header-normal header-color--white">Read Martha’s commentary on food insecurity in The Boston Globe</h5>
                    <a
                      style={{
                        color: "var(--white)",
                      }}
                      className="colored-link"
                      href={`/In-a-season-of-abundance-too-many-Rhode-Boston-Globe.pdf`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Learn More
                      <IconGridArrow fill={'#479130'} />
                    </a>
                  </div>
                </div>
              </Row6633>
            </NestedContainer>
          </FullWidthContainer>
          <FullWidthContainer
            noVerticalPad
            bottomBlockBeige
            background={`var(--color-2)`}
          >
            <NestedContainer>
              <div>
                <TestimonialCallout
                  image={marthaImage}
                  styled
                  quote="We believe that every Rhode Islander should have access to high-quality, affordable, and equitable care—including access to food. Nutritious food is a basic need and essential to creating a healthy Rhode Island for everyone."
                  author="Martha Wofford"
                  role="President & CEO, BCBSRI"
                />
              </div>
            </NestedContainer>
          </FullWidthContainer>
        </div>
        <div id="empowering-kids-through-play">
          <FullWidthContainer
            noBottomPad
            bottomBlockBlue
            background={`var(--white-to-beige)`}
          >
            <SectionHeader
              reportType={"community"}
              title={"Empowering kids through play"}
              intro={<><p className="pb-2">For seven years, Recess Rocks in Rhode Island has helped ensure that recess is a safe, meaningful, and healthy experience for local students. This partnership between BCBSRI, Playworks New England, and the Rhode Island Healthy Schools Coalition provides a free training program for schools and resource staff, sharing activities that help kids stay active and social.</p></>}
            />
            <NestedContainer
              border={`thick-grey`}
              background={`var(--color-27)`}
              padded
            >
              <Row6633
                doubleGap
              >
                <div>
                  <StaticImage
                    src="../images/community/empowering-kids-01.png"
                    height={438}
                    alt=""
                    layout="constrained"
                    className="rounded"
                  />
                  <p className="pt-3 pb-2">
                    This year, Playworks launched their first-ever Active Indoor Recess Week to give educators new resources to help kids be active inside. Fifty-five educators participated in the first event, representing 24 schools and more than 1,000 students.
                  </p>
                </div>
                <div>
                  <div className="center">
                    <StaticImage
                      src="../images/community/icon-chalk.png"
                      alt=""
                      className="icon-chalk"
                    />
                    <div className="o-divider o-divider-gray-100"></div>
                    <div>
                      <StatContainer>
                        <Stat
                          stat={"42,330"}
                          description="Rhode Island students impacted since 2016"
                        />
                        <Stat
                          stat={"110"}
                          description="Rhode Island schools have improved their recess culture"
                        />
                      </StatContainer>
                    </div>
                    <div className="o-divider o-divider-gray-100"></div>
                  </div>
                </div>
              </Row6633>
              <Row6633
                doubleGap
                verticalCenter
              >
                <div>
                  <RowEqual
                    className={"wrap-early"}
                  >
                    <StaticImage
                      src="../images/community/empowering-kids-02.png"
                      layout="constrained"
                      className="rounded"
                      alt=""
                    />
                    <StaticImage
                      src="../images/community/empowering-kids-03.png"
                      layout="constrained"
                      className="rounded"
                      alt=""
                    />
                  </RowEqual>
                </div>
                <div>
                  <div className="center">
                    <div className="kids-illustration">
                      <StaticImage
                        src="../images/community/illustration-walking.png"
                        layout="constrained"
                        className="rounded"
                        alt=""
                      />
                      <span>
                        &#8220;It's cool and fun.&#8221;
                        <span><strong>– Third grader</strong>, Mary E. Fogarty Elementary in Providence</span>
                      </span>
                    </div>
                  </div>
                </div>
              </Row6633>
              <Row6633
                doubleGap
              >
                <div className="pt-3">
                  <StaticImage
                    src="../images/community/icon-recess-champions.png"
                    alt="kids"
                    className="community-nutritious__icon"
                  />
                  <h3>Recess Champions</h3>
                  <p>The Recess Champions program celebrates educators who have taken steps to prioritize play and social-emotional learning.</p>
                  <p>The following educators were 2022 Recess Champions:</p>
                  <ul className="community-bullet bullet__list">
                    <li className="bullet__list-items"><strong>Ken Anderson</strong>, Physical Education Teacher, Mary E. Fogarty Elementary, Providence</li>
                    <li className="bullet__list-items"><strong>Jon Passarelli</strong>, Physical Education Teacher, Mary E. Fogarty Elementary, Providence</li>
                    <li className="bullet__list-items"><strong>Diana Comtois</strong>, School Counselor, North Smithfield Elementary, North Smithfield</li>
                    <li className="bullet__list-items"><strong>Doreen Reilly</strong>, Teacher Assistant, Richmond Elementary, Chariho</li>
                  </ul>
                </div>
                <div></div>
              </Row6633>
            </NestedContainer>
          </FullWidthContainer>
        </div>
        <div id="giving-back-across-the-state">
          <FullWidthContainer
            noBottomPad
            bottomBlockGreen
            background={`var(--color-2)`}
          >
            <SectionHeader
              dark
              reportType={"community"}
              title={"Giving back across the state"}
              intro={<><p>It’s our favorite day of the year—our company-wide day of service. During our 11th annual Blue across Rhode Island, held on Friday, September 16, we partnered with 14 local nonprofit agencies.</p><p>Our employees built houses, transformed school play spaces, painted community centers, and assembled food and hygiene supplies for people in need. In addition to volunteer support, each project site received a financial contribution of $5,000 to assist with their important work.</p></>}
            />
            <NestedContainer
              className="community-giving-back invert-char-color"
            >
              <Row6633
                doubleGap
                verticalCenter
              >
                <div>
                  <h3 className="invert-header-color">See the difference a day can make.</h3>
                  <VideoModal
                    ariaHideApp={false}
                    posterImage={posterImg2}
                    videoPlayIcon={playIcon}
                    videoSource="https://player.vimeo.com/video/797083045?h=c6c1a4d4bc"
                    variant={"customer--large"}
                  />
                  <a
                    style={{
                      marginTop: "1rem",
                      color: "var(--white)",
                    }}
                    className="colored-link"
                    href="https://player.vimeo.com/video/797083045?h=c6c1a4d4bc"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Watch Video
                    <IconGridArrow fill={'#479130'} />
                  </a>
                </div>
                <div className="sidebar__align-center">
                  <div>
                    <StatContainer>
                      <h5 className="invert-header-color">BlueAngel volunteers</h5>
                      <div className="o-divider o-divider-white-100"></div>
                      <Stat
                        stat={"482"}
                        darkbg
                      />
                      <h5 className="invert-header-color">hours donated to our community</h5>
                      <div className="o-divider o-divider-white-100"></div>
                      <Stat
                        stat={"2,311"}
                        darkbg
                      />
                    </StatContainer>
                  </div>
                </div>
              </Row6633>
              <GallerySlider />
            </NestedContainer>
            <NestedContainer
              paddedLast
            >
              <div style={{ marginTop: "4rem" }} className="testimonial-mobile">
                <TestimonialCallout
                  styled
                  quote="It’s not only about building a home. It’s about using the home as a tool to bring the community together. And that’s what makes us stronger."
                  author="Azade Perin-Monterroso"
                  role="Executive Director, Habitat for Humanity of Greater Providence and East Bay"
                />
              </div>
            </NestedContainer>
          </FullWidthContainer>
        </div>
        <div id="expanding-covid-19-coverage">
          <FullWidthContainer
            background={`var(--color-27)`}
          >
            <SectionHeader
              reportType={"community"}
              title={"Expanding COVID-19 coverage"}
            />
            <NestedContainer
              border={`thick-grey`}
              background={`var(--white)`}
              padded
              hasShadow
            >
              <Row6633
                doubleGap
              >
                <div>
                  <StaticImage
                    src="../images/community/expanding-COVID-19-coverage.png"
                    height={438}
                    layout="constrained"
                    className="rounded"
                  />
                </div>
                <div className="sidebar__align-left">
                  <p>
                  In the third year of the pandemic, in accordance with applicable state and federal requirements, our members were covered for convenient home test kits for $0 without a prescription—up to 8 per month. We also continued to protect our members with temporary benefits, including 100% coverage for COVID-19 testing and 100% coverage for COVID-19 treatment in-network, including being prescribed Paxlovid at pharmacies.*
                  </p>
                  <p>
                    Members can find information about their COVID-19 coverage on our{" "}
                    <a
                      href="https://www.bcbsri.com/keepinformed/"
                      target="_blank"
                      rel="noreferrer"
                      className="body-link"
                    >
                      Keeping You Well and Well-Informed site
                    </a>
                    .
                  </p>
                  <p className="footnotes">
                    <small><i>*Includes all fully insured plans. Not all self-funded employers have chosen to cover the benefits without cost share.</i></small>
                  </p>
                  <StatContainer>
                  <Stat
                    stat={"252,834"}
                    description={"test kits covered for $0"}
                  />
                  </StatContainer>
                </div>
              </Row6633>
            </NestedContainer>
          </FullWidthContainer>
        </div>
      </div>
    </Layout>
  );
};

export default CommunityPage;