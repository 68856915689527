import React from 'react';
import "../IconGridArrow/icon-grid-arrow.css";

export default function IconGridArrow({fill}) {
  return (
    <span className="icon-cta">
      <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18">
        <g transform="translate(-79 -2)">
          <g transform="translate(1481 -193) rotate(90)">
            <rect width="18" height="17" rx="4" transform="translate(195 1385)" fill={fill} class="icon-cta-square" />
            <path d="M9.11,20.037.472,16.05a.334.334,0,0,0-.4.093.33.33,0,0,0-.008.408l2.841,3.788L.067,24.126a.332.332,0,0,0,.4.5L9.11,20.641a.332.332,0,0,0,0-.6Z" transform="translate(183.661 1397.389) rotate(-90)" fill="#f7f9fa" class="icon-cta-arrow"/>
          </g>
        </g>
      </svg>
    </span>
  )
}
