// Base
import React from "react";

// Global Layout
import Layout from "../components/layout/layout";

// Components
import Hero from "../components/hero/hero";
import FullWidthContainer from "../components/full-width-container/full-width-container";
import HomepageFinancial from "../components/homepage-financial/homepage-financial";
import SectionCallout from "../components/section-callout/section-callout";
import NestedContainer from "../components/nested-container/nested-container";
import { StaticImage } from "gatsby-plugin-image";
import ContentGrid from "../components/content-grid/content-grid";

const IndexPage = () => {
  return (
    <Layout pageTitle="2022 BCBSRI Mission Report - Homepage">
      <FullWidthContainer singleHero background={`var(--beige-to-white)`}>
        <Hero
          title="Mission Report"
          intro="In 2022, Blue Cross & Blue Shield of Rhode Island (BCBSRI) expanded our commitment to providing access to high-quality, affordable, and equitable care. This is the foundation of everything we do to help Rhode Islanders live their healthiest lives."
          community
          customers
          partners
        >
          <StaticImage
            src="../images/photos/Image-Hero.jpg"
            alt=""
            loading={"eager"}
            aspectRatio={2 / 1}
            className={"home-hero"}
          />
        </Hero>
      </FullWidthContainer>
      <FullWidthContainer single styleName={`container--full--pb-0`} background={`var(--white-to-beige)`}>
        <NestedContainer>
          <ContentGrid />
        </NestedContainer>
      </FullWidthContainer>
      <FullWidthContainer single styleName={`container--full--pt-0 container--full--pb-0`} background={`var(--white)`}>
        <HomepageFinancial />
        <SectionCallout
          url="/financials"
          title="2022 Financial Results"
        />
      </FullWidthContainer>
    </Layout>
  );
};

export default IndexPage;