import * as React from "react";
import "./stat.css";

const Stat = ({
  icon,
  stat,
  description,
  dollarSign,
  plusIcon,
  percentage,
  darkbg,
  label,
  tag
}) => {
  return (
    <div className={`stat-item stat-item--${darkbg ? "darkbg" : "lightbg"}`}>
      <div className="stat-item__label">
        {label}
      </div>
      <div className="stat-item__stat">
        {!!icon && <span className="stat-item__icon"></span>}
        {!!dollarSign && <sup>$</sup>}
        {stat}
        {!!percentage && <sup>%</sup>}
        {!!plusIcon && <span>+</span>}
        {!!tag && <span className="stat-item__stat--label">{tag}</span>}
      </div>
      <div className="stat-item__description">{description}</div>
    </div>
  );
};

export default Stat;
