import * as React from "react";

// Global Layout
import Layout from "../components/layout/layout";

// Components
import Hero from "../components/hero/hero";
import FullWidthContainer from "../components/full-width-container/full-width-container";
import { StaticImage } from "gatsby-plugin-image";
import SectionHeader from "../components/section-header/section-header";
import NestedContainer from "../components/nested-container/nested-container";
import Row6633 from "../components/row/row-66-33";
import RowEqual from "../components/row/row-equal";
import TestimonialCallout from "../components/testimonial-callout/testimonial-callout";
import VideoModal from "../components/video-modal/video-modal";
import IconGridArrow from "../components/IconGridArrow/IconGridArrow";
import StatContainer from "../components/stat/stat-container";
import Stat from "../components/stat/stat";

// Images
import playIcon from "../images/partners/Icon-Play-Video-Purple.svg";
import posterImg1 from "../images/partners/american-heart-association-storytellers.png";

// Styles
import "./provider-partners.css";
import "./styles.pillar-pages.css";

const IndexPage = () => {
  return (
    <Layout pageTitle="2022 BCBSRI Mission Report - Provider Partners">
      <div className="provider-partners-page">
        <FullWidthContainer
          singleHero
          background={`var(--beige-to-white)`}
        >
          <Hero
            title="Provider Partners"
            intro="BCBSRI is teaming up with providers and others to build a higher quality, more affordable, and more equitable healthcare system. A focus on quality over quantity of healthcare. Attempts to address the rising cost of prescription drugs. Encouraging diversity that reflects our community. These initiatives can make a real difference for all Rhode Islanders."
            reportType="partners"
            inner
            community
            customers
          >
            <StaticImage
              src="../images/partners/Partners-Hero.jpg"
              alt=""
              loading={"eager"}
              aspectRatio={5 / 2}
              className={"provider-partners-hero"}
            />
          </Hero>
        </FullWidthContainer>
        <div id="promoting-well-care-over-sick-care">
          <FullWidthContainer
            noBottomPad
            background={`var(--beige-to-burgundy--01)`}
          >
            <SectionHeader
              reportType={"provider partners"}
              title={"Promoting “well care” over “sick care”"}
              intro={
                "We are investing in primary care to enable clinicians to focus on preventive health and keeping patients healthier. Not only is that better for patients, but it also drives down costs, which is better for employers and the system. Physicians who deliver higher quality thrive, receiving greater financial incentives, which enables them to bring on additional care team members and see more patients."
              }
            />
            <NestedContainer
              border={`thick-grey`}
              background={`var(--white)`}
              padded
              hasShadow
            >
              <Row6633
                doubleGap
              >
                <div>
                  <StaticImage
                    src="../images/partners/value-based.png"
                    height={438}
                    layout="constrained"
                    className="rounded"
                  />
                </div>
                <div>
                  <h3>Value-based care with Prospect</h3>
                  <p>In 2022, we launched an exciting arrangement with Prospect Health Services of Rhode Island, compensating them for taking holistic care of their nearly 10,000 BCBSRI Medicare Advantage patients, rather than for specific services provided.</p>
                  <p>Key to this approach is team-based care, where physicians can spend more time with patients who have more acute needs. Then other members of the care team can spend time with patients whose needs are preventive, for instance having a dietitian consult with a patient on a heart-healthy diet.</p>
                </div>
              </Row6633>
              <div style={{ margin: "2rem auto 2rem" }} className="testimonial-mobile">
                <TestimonialCallout
                  styled
                  quote="We are pleased that BCBSRI has taken this bold step forward in supporting value over volume."
                  author="Steve O’Dell"
                  role="CEO, Prospect Health Services of RI"
                />
              </div>
            </NestedContainer>
          </FullWidthContainer>
        </div>
        <div id="addressing-prescription-drug-costs">
          <FullWidthContainer
            noBottomPad
            bottomBlockBlue
            background={`var(--beige-to-burgundy--01-solid)`}
          >
            <SectionHeader
              reportType={"provider partners"}
              title={"Addressing prescription drug costs"}
            />
            <NestedContainer
              border={`thick-grey`}
              background={`var(--white)`}
              padded
              hasShadow
            >
              <Row6633
                doubleGap
                reverse
              >
                <div>
                  <StaticImage
                    src="../images/partners/NCQA.png"
                    layout="constrained"
                    className="rounded"
                    alt=""
                  />
                </div>
                <div>
                  <RowEqual
                    className={"wrap-early force-response"}
                  >
                    <div>
                      <p>
                        While many generic medicines cost less than brand-name drugs, some high-cost generics are more expensive than they need to be due to lack of competition in the market. BCBSRI welcomed the first low-cost generic drug to be produced through our partnership with CivicaScript™, which was cofounded to bring affordable versions of common but high-priced generic medicines to market. CivicaScript introduced tablets to help treat prostate cancer that has spread to other parts of the body. BCBSRI cofounded CivicaScript in 2020 along with Civica Inc., the Blue Cross Blue Shield Association, and 17 other independent and locally operated Blue Cross and Blue Shield companies.
                      </p>
                      <h5 className="header-benton header-normal">Read what our CEO, Martha Wofford, has to say about making healthcare more affordable</h5>
                      <a
                        className="colored-link"
                        href={`/It-will-take-all-of-us-to-make-health-care-more-affordable-The-Boston-Globe.pdf`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Read More
                        <IconGridArrow fill={'#A20067'} />
                      </a>
                    </div>
                    <div className="center">
                      <StaticImage
                        src="../images/partners/logo-civica-bcbsri.png"
                        alt=""
                      />
                      <h4 className="header-color--burgundy">Civicascript’s maximum retail price</h4>
                      <div className="o-divider o-divider-gray-100"></div>
                      <StatContainer>
                        <Stat
                          dollarSign
                          stat={"171"}
                          description="for abiraterone which is"
                          tag="per month"
                        />
                        <Stat
                          dollarSign
                          stat={"3,000"}
                          description="less than the average cost in Medicare Part D"
                          tag="per month"
                          icon
                        />
                      </StatContainer>
                    </div>
                  </RowEqual>
                </div>
              </Row6633>
              <div style={{"margin-top": "3rem"}} className="o-divider o-divider-gray-100"></div>
              <Row6633
                doubleGap
              >
                <div className="pt-1">
                  <StaticImage
                    src="../images/partners/icon-hand-off.svg"
                    alt=""
                  />
                  <h3 className="left">Prescription savings program</h3>
                  <p>In 2022, BCBSRI introduced MedsYourWay®, a drug discount program available to many of our members. It helps them find the lowest available price of applicable discount cards for eligible medications—no coupons or other cards needed.*</p>
                  <p className="footnotes"><small><i>*MedsYourWay is not insurance. It is a drug discount program administered by Prime Therapeutics, LLC, an independent company contracted by BCBSRI to provide pharmacy benefit management services.</i></small></p>
                </div>
                <div></div>
              </Row6633>
              <Row6633
                doubleGap
              >
                <div>
                  <div>
                    <h3>Collective buying power</h3>
                    <StaticImage
                      src="../images/partners/mask-group.png"
                      layout="constrained"
                      className="rounded"
                      alt=""
                    />
                    <p>
                    In 2022, BCBSRI helped lay the groundwork for a new collective purchasing organization, Synergie. Founded by Blue Cross and Blue Shield companies, Synergie will negotiate costly medical benefit drugs—ones that are injected or infused by a healthcare professional in a clinical setting—for 100 million Americans, starting in 2023.
                    </p>
                  </div>
                </div>
                <div>
                  <div className="sidebar__align-center vertical-border-gray">
                    <StaticImage
                      src="../images/partners/newspaper.svg"
                      alt=""
                    />
                    <h5 className="header-color--burgundy">Pharmacists embedded at integra</h5>
                    <div className="o-divider o-divider-gray-100"></div>
                      <StatContainer>
                        <Stat
                          stat={"4"}
                          description="pharmacists"
                        />
                        <Stat
                          dollarSign
                          stat={"7.5"}
                          tag="million"
                          description="cost avoidance*"
                        />
                        <Stat
                          stat={"15,000"}
                          description="BCBSRI Medicare Advantage members"
                        />
                      </StatContainer>
                      <p className="footnotes"><small><i>*Savings include direct and indirect costs in 2022 by pharmacists with Rhode Island Primary Care Physicians Corporation.</i></small></p>
                  </div>
                </div>
              </Row6633>
            </NestedContainer>
          </FullWidthContainer>
        </div>
        <div id="making-behavioral-healthcare-more-equitable">
          <FullWidthContainer
            noBottomPad
            background={`var(--color-2)`}>
            <SectionHeader
              dark
              reportType={"provider partners"}
              title={"Making behavioral healthcare more equitable"}
              intro={
                "The last few years have emphasized the importance of mental healthcare—and the need for increased access to those services. We are collaborating with community partners to ensure that everyone in our community receives affirming and inclusive care."
              }
            />
            <NestedContainer
              styleName={'pt-0 pb-0'}
              className="invert-char-color"
              padded
            >
              <Row6633
                doubleGap
              >
                <div>
                  <div>
                      <h3 className="header-color--white">An open door for the LGBTQ+ community</h3>
                      <StaticImage
                        src="../images/partners/open-door-community.png"
                        layout="constrained"
                        className="rounded"
                        alt=""
                      />
                      <p>
                        Since 2019, we’ve been proud to support Open Door Health, Rhode Island’s first and only clinic dedicated to providing primary care and sexual healthcare to the LGBTQ+ community. In 2022, we helped fund an integrated behavioral healthcare program with on-site psychologists at the clinic.
                      </p>
                  </div>
                </div>
                <div>
                  <div className="sidebar__align-left vertical-border-white">
                    <StaticImage
                      src="../images/partners/icon-support-diverse-mental-health.svg"
                      alt=""
                    />
                    <h3 className="invert-header-color">Support for diverse mental health professionals</h3>
                    <p>In the behavioral health field, the lack of diversity among providers contributes to a lack of diversity in people seeking mental healthcare, including the BIPOC and LGBTQ+ communities as well as speakers of world languages. That’s why BCBSRI is helping fund the Mental Health Association of Rhode Island’s Diversity, Equity, and Inclusion Pipeline. Through this program, scholarships and internship stipends are awarded to graduate students from diverse backgrounds who will eventually practice in Rhode Island.</p>
                    <StatContainer>
                      <Stat
                        dollarSign
                        stat={"75,000"}
                        darkbg
                        description={"in funding in 2022"}
                      />
                    </StatContainer>
                  </div>
                </div>
              </Row6633>
            </NestedContainer>
            <NestedContainer
            styleName={'pt-0'}
              className="invert-char-color"
              padded
            >
              <Row6633
                doubleGap
              >
                <div>
                  <StaticImage
                    src="../images/partners/icon-latinos-mental-health.svg"
                    alt=""
                  />
                  <h3 className="header-color--white left">Latinos & Mental Health Project</h3>
                  <p>The Latino Policy Institute at Roger Williams University has partnered with BCBSRI and the Latino Mental Health Network of Rhode Island to implement the Latinos & Mental Health Project. It is analyzing barriers and opportunities in the Rhode Island workforce pipeline for mental health professionals. Next year it will propose improvements and investments in the mental health workforce pipeline for multilingual and historically underserved communities and providers.</p>
                </div>
                <div> </div>
              </Row6633>
            </NestedContainer>
            </FullWidthContainer>
            <FullWidthContainer
              noTopPad
              topBlockBlue
              background={`var(--beige-to-burgundy--01-solid)`}
            >
            <NestedContainer
              border={`thick-grey`}
              background={`var(--white)`}
              padded
              hasShadow
            >
              <Row6633
                verticalCenter
              >
                <div>
                  <RowEqual className="wrap-early">
                    <div>
                      <div>
                        <StaticImage
                          src="../images/partners/icon-navigation-crossroads.svg"
                          alt="meals for older adults"
                        />
                        <h3>Navigation for Crossroads clients at high risk</h3>
                        <p>
                        In 2022, BCBSRI funded a new program at Crossroads that provides mental health navigation services to clients in Crossroads’ Domestic Violence Program, Women’s Shelter, Family Shelter, and Rapid Rehousing programs. Having this targeted support will help clients improve their mental health and achieve housing stability.
                        </p>
                      </div>
                      <div>
                        <StatContainer>
                          <Stat
                            stat={"150"}
                            description={"Crossroads clients helped in 2022-2023"}
                          />
                        </StatContainer>
                      </div>
                    </div>
                    <div>
                      <div>
                        <StaticImage
                          src="../images/partners/icon-behavioral-healthcare-clinic.svg"
                          alt="food pantry"
                        />
                        <h3>Behavioral healthcare right in the (free) clinic</h3>
                        <p>
                          COVID-19 has highlighted the need for more support for the behavioral and social needs of clients at the Rhode Island Free Clinic. With support from BCBSRI, the Free Clinic welcomed their first full-time behavioral health clinician in 2022.
                        </p>
                      </div>
                      <div>
                        <StatContainer>
                          <Stat
                            stat={"2,617"}
                            description={"Rhode Islanders impacted by Free Clinic programs"}
                          />
                        </StatContainer>
                      </div>
                    </div>
                  </RowEqual>
                </div>
                <div>
                  <StaticImage
                    src="../images/partners/behavioral-healthcare-clinic.png"
                    layout="constrained"
                    className="rounded"
                  />
                </div>
              </Row6633>
            </NestedContainer>
          </FullWidthContainer>
        </div>
        <div id="encouraging-providers-and-suppliers">
          <FullWidthContainer
            noTopPad
            noBottomPad
            background={`var(--beige-to-burgundy--01-rev)`}
          >
            <SectionHeader
              reportType={"provider partners"}
              title={"Encouraging providers and suppliers"}
              intro={
                "BCBSRI is committed to creating opportunities for all Rhode Islanders, from the providers who see our members to the companies we do business with."
              }
            />
            <NestedContainer
              border={`thick-grey`}
              background={`var(--white)`}
              padded
              hasShadow
            >
              <Row6633 reverse>
                <div>
                  <StaticImage
                    src="../images/partners/safe-zones.png"
                    height={438}
                    layout="constrained"
                    className="rounded"
                  />
                </div>
                <div>
                  <StaticImage
                    src="../images/partners/Logo-Safe-Zone.png"
                    alt="Safe Zone"
                    class="safe-zone-logo"
                  />
                  <h3 className="left">Safe Zones</h3>
                  <p>2022 saw a significant increase in the number of BCBSRI-certified Safe Zone providers offering safe, affirming, and inclusive care to the LGBTQ+ community.</p>
                  <p>The Safe Zone program has grown to include adult and pediatric primary care, behavioral health, oral health, assisted living for older adults, child and family services, and organizations serving individuals who have experienced sexual assault, domestic abuse, and substance abuse.</p>
                  <p>Certification requirements for BCBSRI LGBTQ+ Safe Zones include staff training specific to the care of LGBTQ+ patients, protection for patients and staff from discrimination based on gender identity or expression, gender-neutral bathrooms, inclusive forms and procedures, and a public commitment to connecting with and serving the LGBTQ+ community.</p>
                <div>
                  <div className="o-divider o-divider-gray-100"></div>
                  <RowEqual
                    className={"wrap-early"}
                  >
                    <div>
                      <div>
                        <StatContainer>
                          <Stat
                            plusIcon
                            stat={"70"}
                            description={"Safe Zone providers"}
                          />
                        </StatContainer>
                      </div>
                      <a style={{
                          display: "block",
                          lineHeight: "140%",
                        }}
                        className="colored-link"
                        href="https://www.bcbsri.com/safezones"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Find a certified Safe Zone provider
                        <IconGridArrow fill={'#A20067'} />
                      </a>
                    </div>
                    <div>
                      <div className="customers__podcast-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 29">
                          <path
                            d="M9 23.951V27H7a1 1 0 0 0 0 2h6a1 1 0 0 0 0-2h-2v-3.049A10 10 0 0 0 20 14v-1a1 1 0 0 0-2 0v1a8 8 0 0 1-16 0v-1a1 1 0 0 0-2 0v1a10 10 0 0 0 9 9.951zM4.34 4c-.115.325-.201.66-.258 1H6.5a.5.5 0 0 1 0 1H4v1h2.5a.5.5 0 0 1 0 1H4v6a6 6 0 1 0 12 0V8h-2.5a.5.5 0 0 1 0-1H16V6h-2.5a.5.5 0 0 1 0-1h2.417a5.949 5.949 0 0 0-.259-1H13.5a.5.5 0 0 1 0-1h1.7A6 6 0 0 0 4.8 3h1.7a.5.5 0 0 1 0 1H4.34z"
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            fill="#A20067"
                          />
                        </svg>
                      </div>
                      <h4 className="left pt-1 header-benton-reg header-cap">The Rhode to Pride</h4>
                      <a
                        href="https://www.buzzsprout.com/1977033/10837462-the-rhode-to-pride"
                        className="btn btn--burgandy"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span>listen to podcast</span>
                      </a>
                    </div>
                  </RowEqual>
                  </div>
                </div>
              </Row6633>
              <div style={{ margin: "4rem auto 0" }} className="testimonial-mobile">
              <TestimonialCallout
                styled
                quote="The Safe Zone program was created to help address the historical challenges the LGBTQ+ community has encountered in obtaining healthcare."
                author="Jenny Bautista-Ravreby"
                role="Diversity, Equity, and Inclusion Manager, BCBSRI"
              />
              </div>
            </NestedContainer>
            <NestedContainer
              className="invert-char-color"
              background={`var(--color-2)`}
              padded
            >
              <Row6633
                verticalCenter
              >
                <div>
                  <StaticImage
                    src="../images/partners/supplier-diversity.png"
                    height={438}
                    layout="constrained"
                    className="rounded"
                  />
                </div>
                <div>
                  <h3 className="header-color--white">Supplier Diversity</h3>
                  <p>
                  BCBSRI brought on board a dedicated Supplier Diversity Program Manager, Doris De Los Santos, to lead supplier diversity efforts within the organization and build relationships externally to help us achieve our short- and long-term supplier diversity goals. We are committed to increasing our procurement of products and services from minority-, women-, LGBTQ-, disability-, and veteran-owned businesses, as well as small disadvantaged, HUBZone, and local small businesses.
                  </p>
                </div>
              </Row6633>
              <div style={{ margin: "4rem auto 2rem" }} className="testimonial-mobile">
                <TestimonialCallout
                  styled
                  quote="I am ready to get to work on the supplier diversity front. This is the perfect time to support diverse businesses in Rhode Island, as the state continues to recover from the effects of the pandemic."
                  author="Doris De Los Santos"
                  role="Supplier Diversity Program Manager, BCBSRI"
                  url="https://www.bcbsri.com/newsroom/press-releases/doris-de-los-santos-joins-blue-cross-blue-shield-rhode-island-supplier"
                  linkText="Learn about Doris’ impressive work"
                  fillColor="#a20067"
                />
              </div>
            </NestedContainer>
          </FullWidthContainer>
        </div>
        <div id="reaching-more-rhode-islanders-with-needed-services">
          <FullWidthContainer
            styleName={'partners__reaching-more'}
            background={`var(--white-to-beige)`}
          >
            <SectionHeader
              reportType={"provider partners"}
              title={"Reaching more Rhode Islanders with needed services"}
              intro={<><div className="pb-2">At BCBSRI, we’re here to help improve the well-being of everyone in our state. That’s why we’re proud to support organizations that provide free services to our fellow Rhode Islanders.</div></>}
            />
            <NestedContainer
              border={`thick-grey`}
              background={`var(--white)`}
              padded
              hasShadow
            >
              <Row6633>
                <div>
                  <h3>A technology upgrade that helps improve lives</h3>
                  <StaticImage
                    src="../images/partners/iphone-in-hands.png"
                    height={438}
                    layout="constrained"
                    className="rounded"
                  />
                  <p>In 2022, BCBSRI provided $1.5 million in funding to enhance the capabilities of 211, United Way of Rhode Island’s free 24/7 resource for people in need of housing, food, and other assistance.</p>
                  <p>Now, when someone calls 211 for help or lets a healthcare provider know they are in need, their information is entered into a confidential technology platform that connects a network of health and social care providers. An electronic referral is tracked in the system, allowing 211 staff and healthcare providers to ensure that individuals are connected with the best program to address their needs.</p>
                </div>
                <div className="mb-show">
                  <TestimonialCallout
                    styled
                    quote="We need to ensure Rhode Islanders have safe and affordable housing and nutritious food, and that we’re able to measure the benefits on the health of individuals and communities. We are beyond thankful to BCBSRI for not only sharing this vision, but also for their generous support of the work to get there."
                    author="Larry Warner"
                    role="DrPH, MPH, Chief Impact & Equity Officer, United Way"
                  />
                </div>
                <div className="pt-4">
                  <StaticImage
                    src="../images/partners/icon-health-programs-neighborhoods.svg"
                    alt="Fairy Gardens"
                  />
                  <h3>Health programs in neighborhoods</h3>
                  <p>
                    To help Clínica Esperanza/Hope Clinic expand access to programs, we supported their recently established Neighborhood Health Station, which offers free walk-in health screenings as well as walking clubs, Zumba, and Vida Pura, a program that outreaches to individuals struggling with substance use.
                  </p>
                  <StatContainer>
                    <Stat
                      stat={"7,760"}
                      description={"people received health screenings"}
                    />
                  </StatContainer>
                </div>
              </Row6633>
              <div style={{ margin: "2rem auto 0rem" }} className="testimonial-mobile mb-hide">
                <TestimonialCallout
                  styled
                  quote="We need to ensure Rhode Islanders have safe and affordable housing and nutritious food, and that we’re able to measure the benefits on the health of individuals and communities. We are beyond thankful to BCBSRI for not only sharing this vision, but also for their generous support of the work to get there."
                  author="Larry Warner"
                  role="DrPH, MPH, Chief Impact & Equity Officer, United Way"
                />
              </div>
            </NestedContainer>
          </FullWidthContainer>
        </div>
        <div id="putting-our-hearts-into-fighting-heart-disease">
          <FullWidthContainer
            noTopPad
            background={`var(--beige-to-burgundy--02)`}
          >
            <NestedContainer
              background={`var(--color-2)`}
              padded
            >
              <SectionHeader
                reportType={"provider partners"}
                title={"Putting our hearts into fighting heart disease"}
                dark
              />
              <div>
                <Row6633
                  doubleGap
                >
                  <div>
                    <VideoModal
                      ariaHideApp={false}
                      posterImage={posterImg1}
                      videoPlayIcon={playIcon}
                      videoSource="https://player.vimeo.com/video/799559218?h=f692693689"
                      variant={"customer--large"}
                    />
                    <a
                      style={{
                        marginTop: "1rem",
                        marginBottom: "2rem",
                        color: "var(--white)",
                      }}
                      className="colored-link"
                      href="https://player.vimeo.com/video/799559218?h=f692693689"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Watch Video
                      <IconGridArrow fill={'#a20067'} />
                    </a>
                  </div>
                  <div>
                    <div>
                      <div className="sidebar__align-center">
                      <h5 className="invert-header-color">Donated to the aha on behalf of the heart2heart storytellers</h5>
                      <div className="o-divider o-divider-white-100"></div>
                        <StatContainer>
                          <Stat
                            dollarSign
                            darkbg
                            stat={"25,000"}
                          />
                        </StatContainer>
                        <div className="sidebar__align-left invert-char-color">
                          <p>BCBSRI is a long-time partner of the American Heart Association (AHA) and was proud to have 50+ walkers in the 2022 Heart Walk—the first in-person walk since COVID-19. Our employees raised more than $11,760 to support heart health. We also continued our Heart2Heart Storytellers campaign, in which local women share their stories of surviving heart disease and stroke to raise awareness about these leading causes of death.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Row6633>
              </div>
              <div>
                <Row6633
                  verticalCenter
                  reverse
                >
                  <div>
                    <StaticImage
                      src="../images/partners/fighting-heart-disease-1.png"
                      height={438}
                      layout="constrained"
                      className="rounded"
                    />
                  </div>
                  <div>
                    <StaticImage
                      src="../images/partners/fighting-heart-disease-2.png"
                      height={398}
                      layout="constrained"
                      className="rounded"
                    />
                  </div>
                </Row6633>
              </div>
            </NestedContainer>
          </FullWidthContainer>
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;